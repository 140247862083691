import { getCurrentDomain } from '../common/cookies';

export function getDomain() {
  const domain = getCurrentDomain().split(':')[0];
  return domain.substring(1);
}

export function getProfileOrigin() {
  const env = window.STV.environment;
  const domain = getDomain();

  switch (env) {
      case 'local': return `http://my.${domain}:3000`;
      case 'aws-live': return `https://my.${domain}`;
      default: return `https://my.${domain}`;
  }
}

export function getDataAPIOrigin() {
  const domain = getDomain();
  return window.STV.environment === 'local'
      ? `http://data.api.${domain}:3033`
      : `https://data.api.${domain}`;
}

export function getEnvironment() {
  const env = window.STV.environment;
  switch (env) {
      case 'local': return 'development';
      case 'aws-dev': return 'staging';
      case 'aws-live': return 'production';
      default: return 'production';
  }
}
