
export default function createCarousel({
  parent,
  wrapper,
  items,
  leftButton,
  rightButton,
  width='fill',
  easing=easeInOutQuad,
  spacing,
  stagger,
  animationLength = 1000,
  autoScrollTimer = 4000,
  fade = .5
}) {
  if (items.length < 2 || !wrapper || !parent ) return;
 
  const actualLength = items.length;
  items = addProxyItems(items, wrapper);

  let props = {
    items,
    width: (width === 'fill') ? wrapper.getBoundingClientRect().width : width,
    easing,
    spacing,
    stagger,
    fade
  };

  let index = 0;
  let autoScrollInterval = undefined;
  let resizeTimeout = undefined;
  let autoScrollTimeout = undefined;
  let isAnimating = false;

  positionCarouselItems({ ...props, position: index + 2 });

  if (width === 'fill') {
    window.addEventListener('resize', evt => {
      if (!resizeTimeout) resizeTimeout = setTimeout(() => {
        props = { ...props, width: wrapper.getBoundingClientRect().width };
        positionCarouselItems({ ...props, position: index + 2 });
        resizeTimeout = undefined;
      }, 200);
    });
  }

  addClickToScrollEvent({ delta: -1, element: rightButton });
  addClickToScrollEvent({ delta: 1, element: leftButton });

  if (autoScrollTimer) autoScroll();

  function autoScroll() {
    if (!isAnimating) moveCarousel(getFromTo(1));
  };

  function addClickToScrollEvent({ delta, element }) {
      if (element) {
          element.addEventListener('click', evt => {
              evt.preventDefault();
              if (!isAnimating) {
                  clearTimeout(autoScrollTimeout);
                  moveCarousel(getFromTo(delta));
              }
          })
      };
  }

  function getFromTo(delta) {
    let newIndex;
    if (delta >= 0) {
        newIndex = (index + delta) % actualLength;
    } else {
        newIndex = (index + delta);
        if (newIndex < 0) newIndex = actualLength + newIndex;
    }

    const hasLooped =
        ((delta > 0) && (newIndex < index)) ||
        ((delta < 0) && (newIndex > index));

    const from = hasLooped
        ? (delta > 0)
            ? 1
            : items.length - 2
        : index + 2;

    const to =
        hasLooped
        ? (delta > 0)
            ? 2
            : items.length - 3
        : newIndex + 2;

    index = newIndex;

    return { from, to };
  }

  function moveCarousel({ from, to }) {
    isAnimating = true;
    requestAnimationFrame(tween({
      from,
      to,
      frames: animationLength * .06,
      callback: position => positionCarouselItems({ ...props, position }),
      onComplete: () => {
          isAnimating = false;
          if (autoScrollTimer) autoScrollTimeout = setTimeout(autoScroll, autoScrollTimer);
      }
    }));
  }
}

function tween({ from, to, frames, frame=0, callback, onComplete }) {
  return () => {
    const v = from + ((to-from) * (frame/frames));
    if (callback) callback(v);
    if (frame < frames) {
        requestAnimationFrame(tween({ from, to, frames, frame: frame+1, callback, onComplete }));
    } else {
        if (onComplete) onComplete();
    }
  }
}

function addProxyItems(items, wrapper) {
  const first = items[0].cloneNode(true);
  const second = items[1].cloneNode(true);

  const last = items[items.length - 1].cloneNode(true);
  const secondLast = items[items.length - 2].cloneNode(true);

  wrapper.appendChild(first);
  wrapper.appendChild(second);
  wrapper.appendChild(secondLast);
  wrapper.appendChild(last);
  return [ secondLast, last, ...items, first, second ];
}

function positionCarouselItems({ items, spacing, width, position=0, easing=null, stagger=0, fade }) {
  const vendors = ['webkit', 'moz', 'ms', 'o'];
  stagger = stagger / items.length;
  items.forEach((item, index) => {
    const spaceOffset = (spacing * index);
    const widthOffset = (width * index);
    let positionWithStagger = ((position % 1) / (1 - (stagger * items.length))) - (index * stagger);
    if (positionWithStagger > 1) positionWithStagger = 1;
    if (positionWithStagger < 0) positionWithStagger = 0;

    const positionOffset = easing
      ? -(Math.floor(position) + easing(positionWithStagger, 0, 1, 1 )) * (width + spacing)
      : -(position * (width + spacing));

    if (fade) {
      const opacity = Math.max(Math.min(1 - Math.abs(position - index), 1), fade);
      item.style.opacity = opacity;
    }

    const translation = translate({ x: widthOffset + spaceOffset + positionOffset, y: 0 });

    item.style.position = 'absolute';
    item.style.top = '0px';
    item.style.left = '0px';
    // item.style.willChange = 'transform';
    item.style.transform = translation;
    vendors.forEach(vendor => item.style[`${vendor}Transform`] = translation);
  });
}

function translate({ x=0, y=0 } = {}) {
  return `translate3d(${x}px, ${y}px, 0)`;
}

function easeInOutQuad(t, b, c, d) {
  if ((t/=d/2) < 1) return c/2*t*t + b;
  return -c/2 * ((--t)*(t-2) - 1) + b;
};
