
import { getDataAPIOrigin } from '../../helpers';
const dataApiOrigin = getDataAPIOrigin();

export function validateCompsTermsCheckBox($checkbox) {
    const isChecked = $checkbox.checked;
    if (isChecked === false) {
        alert('Please tick the box to confirm that you have read the specific rules for this competition.');
    }
    return isChecked;
}


export function handleCompetitionEntryError({ event, competitionTitle, _UAParser }) {
    event.preventDefault();
    const { browser, engine, os, device } = _UAParser;
    const origin = window.location.hostname + window.location.pathname;
    const deviceModel = device.model ? device.model : '';
    const deviceType = device.type ? device.type : '';
    const deviceVendor = device.vendor ? device.vendor : '';

    const emailMessage = `mailto:viewerenquiries@stv.tv?subject=Can’t enter competition&body=I tried to enter a competition but it did not work. Please help.%0D%0A%0D%0A` +
                         `Competition title: ${competitionTitle}%0D%0A%0D%0A` +
                         `Browser: ${browser.name} ${browser.version} ${engine.name} ${engine.version}%0D%0A%0D%0A` +
                         `OS: ${os.name} ${os.version}%0D%0A%0D%0A` +
                         `${deviceModel ? 'Device: ' + deviceModel + ' ' + deviceType + ' ' + deviceVendor + '%0D%0A%0D%0A' : ''}` +
                         `Origin: ${origin}%0D%0A%0D%0A` +
                         `Source: Web`;

    // open email client with the email message
    document.location.href = emailMessage;

    return emailMessage;
}


export function getThirdPartyMarketingValue(checkboxBooleanValue) {
    return checkboxBooleanValue ? '1' : '0';
}

export function postCompetitionEntry({ competitionId, userId, scl, referral, optionId, prizeOptionId, answer, tmarketing }) {
  return fetch(`${dataApiOrigin}/v1/competitions/entries/`, {
        method: 'POST',
        body: JSON.stringify({
            competitionId,
            userId,
            scl,
            referral,
            optionId,
            prizeOptionId,
            answer,
            tmarketing,
        })
    });
}
