const Broadcaster = {
    _alloc() {
        if ( !this._events ) {
            this._events = {};
        }
    },

    broadcast( eventId, data = {}) {
        this._alloc();
        const callBacks = this._events[eventId];
        if ( callBacks ) {
            callBacks.forEach(callBack => callBack !== undefined && callBack(data));
        }
    },

    on( eventId, callBack ) {
        this._alloc();
        if ( !this._events[eventId] ) this._events[eventId] = [];
        if ( callBack ) {
            this._events[eventId].push(callBack);
        }

        // Return a callback object with callback id and event id, for use with the off function.
        return { eventId,  callBackId: this._events[eventId].length - 1 };
    },

    off(callBackObject) {
        this._events[callBackObject.eventId][callBackObject.callBackId] = undefined;
        callBackObject = undefined;
    },

    once(eventId, callBack) {
        const callBackObject = this.on(eventId, data => {
            this.off(callBackObject);
            callBack(data);
        });
        return callBackObject;
    }
};

export default Broadcaster;
