
import { Broadcaster, Component, arraySelector, getComponent, Implements, setStyle, throttle, parseQueryString } from '../common/stv';
import animate from "../../../libs/velocity.min";
import { windowSize } from './window-size';

const INITIAL_STYLES    = { zIndex: 9999999 };
const FULLSCREEN_STYLES = { position: 'absolute', top: 0, left: 0, width: '100%' };

const SHADE_STYLES      = { backgroundColor: 'rgba(0,0,0,0.75)', willChange: 'transform, opacity', opacity: 0, visibility: 'hidden' };
const SHADE_FIXED       = { backgroundPosition: "50% 0", backgroundSize: "cover", position: "fixed" };

const PAGE_FILL_STYLES  = { position: 'absolute', top: 0, left: 0, overflow: 'hidden' };
const PAGE_REG_STYLES   = { position: '', top: '', left: '', overflow: '', width: '', height: '' };
const PAGE_SELECTOR     = '.global-page-container';

@Component({
    name: 'static-modal-group'
})
@Implements(Broadcaster)
export default class StaticModalGroup {
    modals              = [];
    activeModalIndex    = undefined;
    trans               = undefined;
    fill                = undefined;
    shadeVisisble       = false;
    activeShade         = undefined;
    pageContent         = undefined;
    modeFunction        = undefined;
    nextModalTimer      = undefined;
    queue               = [];

    constructor() {
        this.options = {
            bgCssClass: 'registration-modal-group__bg',
            animDuration: 500
        };

        this.animationInProgress = false;
    }

    componentHasMounted() {
        const win = window;
        const urlModalID = parseQueryString(window.location.href).modal;
        const closeModals = [].slice.call(this.elements.root.querySelectorAll('[data-close-modal]'));

        document.body.appendChild(this.elements.root);

        closeModals.forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();
                this.close();     
            });
        });

        this.pageContent    = document.querySelector(PAGE_SELECTOR);

        win.smg = this;

        this._buildInitialState();
        this.modals = this.children.find('static-modal').map(item => item.instance);

        win.addEventListener('resize', throttle(this._reposition, 100, this));
        win.addEventListener('load', this._reposition.bind(this));

        setStyle(this.elements.root, { 'overflow': 'hidden' });
        this._reposition();

        if (this.modalIsUrlControllable(urlModalID)) {
            this.open(urlModalID);
        }
    }

    modalIsUrlControllable(modalID) {
        const modal = this.modals.filter(_modal => _modal.id === modalID)[0];
        return modal && modal.options.urlControllable;
    }

    open(id) {
        let modalIndex  = this._getModalIndex(id);
        if (this.nextModalTimer) {
            this.queue.push(id);
            return;
        }

        setStyle(this.elements.root, { 'overflow': 'visible', display: 'block' });

        if (modalIndex !== undefined) {
            let activeModal = this.modals[modalIndex];
            let hideContent;

            // this flag prevents closing the modal before animation has completed
            this.animationInProgress = true;

            if (!this.shadeVisisble) {
                let shadeStyle = activeModal.options.shadeStyle;
                hideContent = (shadeStyle === 'fill');

                if (shadeStyle != this.activeShade && this.activeShade !== undefined) {
                    this._hideShade(this._getShade(this.activeShade));
                }
                if (!this.activeShade) {
                    this._showShade(this._getShade(shadeStyle));
                    this.activeShade = shadeStyle;
                }
            }
            this._closeActiveModal();
            this.activeModalIndex = modalIndex;

            if (activeModal.options.timeOut) {
                this.nextModalTimer = setTimeout(this.nextModal, activeModal.options.timeOut);
            }

            if (hideContent) {
                return activeModal.open()
                    .then(() => {
                        this.modeFunction = this._setFixedMode;
                        this._reposition();
                        activeModal._reposition();

                        // disable flag, animatino completed
                        this.animationInProgress = false;
                    });
            } else {
                this.modeFunction = this._setFreeMode;
                this._reposition();

                // disable flag, animatino completed
                this.animationInProgress = false;

                return activeModal.open();
            }
        }
    }

    nextModal = () => {
        this.nextModalTimer = undefined;
        if (this.queue.length) {
            const nextModalId = this.queue.reverse().pop();
            this.open(nextModalId);
            this.queue.reverse();
        } else {
            this.close();
        }
    };

    close() {
        // do not close if opening animation still in progress
        if (this.animationInProgress) return;

        this._hideShade(this._getShade(this.activeShade));
        this._setFreeMode();
        this.modeFunction = this._setFreeMode();
        this.shadeVisisble = false;
        return this._closeActiveModal()
            .then(() => setStyle(this.elements.root, { 'overflow': 'hidden' }));
    }

    _setFixedMode() {
        let styles = {};
        let winSize = windowSize();

        Object.assign(styles, {width: winSize.width, height: winSize.height}, PAGE_FILL_STYLES);
        setStyle(this.pageContent, styles);
    }

    _setFreeMode() {
        let styles = {}
        Object.assign(styles, PAGE_REG_STYLES);
        setStyle(this.pageContent, styles);
    }

    _getShade(shadeStyle) {
        return (shadeStyle === 'fill') ? this.fill : this.trans;
    }

    _showShade(shade) {
        return new Promise((resolve, reject) => {
            requestAnimationFrame(animate
                .bind(this, shade, {opacity: 1}, {
                    duration: this.options.animDuration,
                    visibility: "visible",
                    complete: resolve
                }));
        });
    }

    _hideShade(shade) {
        return new Promise((resolve, reject) => {
            requestAnimationFrame(animate
                .bind(this, shade, {opacity: 0}, {
                    duration: this.options.animDuration,
                    visibility: "hidden",
                    complete: resolve
                }));
            this.activeShade = undefined;
        });
    }

    _reposition() {
        if (this.modeFunction) this.modeFunction();

        let winSize = this._pollDocumentDimensions();
        let vpSize  = windowSize();

        winSize.height = (winSize.height < vpSize.height) ? vpSize.height : winSize.height;

        setStyle(this.trans, winSize);
        setStyle(this.fill, vpSize);
    }

    _buildInitialState() {
        this.trans = document.createElement('div');
        this.fill = document.createElement('div');

        this.trans.addEventListener('click', (e) => this.broadcast('shade-click', e));
        this.fill.addEventListener('click', (e) => this.broadcast('shade-click', e));

        setStyle(this.elements.root, INITIAL_STYLES);
        setStyle(this.elements.root, FULLSCREEN_STYLES);
        this._updateShadeStyle();
        this.elements.root.appendChild(this.trans);
        this.elements.root.appendChild(this.fill);
    }

    _updateShadeStyle() {
        let transStyle  = {};
        let fillStyle   = {};

        Object.assign(transStyle, FULLSCREEN_STYLES, SHADE_STYLES, {zIndex: -1});
        Object.assign(fillStyle, FULLSCREEN_STYLES, SHADE_STYLES, SHADE_FIXED, {zIndex: -1});

        setStyle(this.trans, transStyle);
        setStyle(this.fill, fillStyle);
        this.fill.classList.add(this.options.bgCssClass);
    }

    _closeActiveModal() {
        let activeModalIndex = this.activeModalIndex;
        // Minifier does something really silly with undefined as a type checking
        if (typeof activeModalIndex !== 'undefined') {
            this.activeModalIndex = undefined;
            return this.modals[activeModalIndex]
                .close().then(() => {
                    this.broadcast('active-modal-closed');
                });
        }

        return Promise.resolve();
    }

    _getModalIndex(id) {
        for (let i=0; i<this.modals.length; i++) {
            if (id === this.modals[i].id) return i;
        }
        return undefined;
    }

    _pollDocumentDimensions() {
        return { width: document.body.clientWidth, height: document.body.clientHeight };
    }
}