(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  typeof define === 'function' && define.amd ? define(factory) :
  (global.LoginService = factory());
}(this, (function () { 'use strict';

  var environmentConfig = {
    production: {
      apiGatewayUrl: 'https://data.api.stv.tv/v2/',
      playerApiUrl: 'https://player.api.stv.tv/v1/',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_YJtAWaIgn',
      clientId: '3qf6kbka0bcvu1387nm0fq36s3'
    },
    staging: {
      apiGatewayUrl: 'https://data.api.stvqa.tv/v2/',
      playerApiUrl: 'https://player.api.stvqa.tv/v1/',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_zxbSXLsBD',
      clientId: '6a4dbuf43mppsl588p4di9ot5t'
    },
    development: {
      apiGatewayUrl: 'https://data.api.stvqa.tv/v2/',
      playerApiUrl: 'https://player.api.stvqa.tv/v1/',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_zxbSXLsBD',
      clientId: '6a4dbuf43mppsl588p4di9ot5t'
    }
  };

  var _require = require('amazon-cognito-identity-js'),
      AuthenticationDetails = _require.AuthenticationDetails,
      CognitoUser = _require.CognitoUser,
      CookieStorage = _require.CookieStorage,
      CognitoUserPool = _require.CognitoUserPool,
      CognitoUserAttribute = _require.CognitoUserAttribute;

  var jwtDecode = require('jwt-decode');

  var _require2 = require('js-base64'),
      Base64 = _require2.Base64;

  var qs = require('qs');
  var Cookies = require('js-cookie');
  var defaultCookieExpires = 365;

  var getQueryString = function getQueryString() {
    return process.browser && qs.parse(location.search);
  };

  var setStvCookies = function setStvCookies(_ref) {
    var postcode = _ref.postcode,
        userId = _ref.userId,
        domain = _ref.domain,
        path = _ref.path,
        groupToken = _ref.groupToken,
        _ref$expires = _ref.expires,
        expires = _ref$expires === undefined ? defaultCookieExpires : _ref$expires,
        _ref$secure = _ref.secure,
        secure = _ref$secure === undefined ? false : _ref$secure;

    Cookies.set('STV_POSTCODE', postcode, { expires: expires, domain: domain, secure: secure, path: path });
    Cookies.set('STV_USERID', userId, { expires: expires, domain: domain, secure: secure, path: path });
    Cookies.set('STV_GROUPTOKEN', groupToken, { expires: expires, domain: domain, secure: secure, path: path });
  };

  var removeStvCookies = function removeStvCookies(_ref2) {
    var domain = _ref2.domain,
        path = _ref2.path;

    Cookies.remove('STV_POSTCODE', { domain: domain, path: path });
    Cookies.remove('STV_USERID', { domain: domain, path: path });
    Cookies.remove('STV_GROUPTOKEN', { domain: domain, path: path });
  };

  var LoginService = function LoginService(_ref3) {
    var currentDomain = _ref3.currentDomain,
        environment = _ref3.environment,
        isSecureProtocol = _ref3.isSecureProtocol,
        _ref3$platform = _ref3.platform,
        platform = _ref3$platform === undefined ? 'desktop' : _ref3$platform;

    var poolData = {
      apiGatewayUrl: environmentConfig[environment].apiGatewayUrl,
      UserPoolId: environmentConfig[environment].userPoolId,
      ClientId: environmentConfig[environment].clientId,
      Storage: new CookieStorage({
        domain: '.' + currentDomain,
        secure: isSecureProtocol,
        expires: defaultCookieExpires
      })
    };
    var userPool = new CognitoUserPool(poolData);

    var getGroupToken = function getGroupToken(_ref4) {
      var postcode = _ref4.postcode,
          userId = _ref4.userId;

      var gateway = environmentConfig[environment].playerApiUrl;
      var endpoint = 'grouptoken';
      var params = qs.stringify({
        postcode: postcode,
        gigyaId: userId,
        device: platform
      });

      return fetch('' + gateway + endpoint + '?' + params).then(function (response) {
        return response.json();
      }).then(function (responseJson) {
        if (responseJson.success && responseJson.results.grouptoken) {
          return responseJson.results.grouptoken;
        }
        throw new Error('Unable to fetch groupToken');
      });
    };

    var signInUser = function signInUser(_ref5) {
      var email = _ref5.email,
          password = _ref5.password;

      var authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password
      });
      var userData = {
        Username: email,
        Pool: userPool,
        Storage: new CookieStorage({
          domain: '.' + currentDomain,
          secure: isSecureProtocol,
          expires: defaultCookieExpires
        })
      };
      var cognitoUser = new CognitoUser(userData);

      return authenticateUser({ cognitoUser: cognitoUser, authenticationDetails: authenticationDetails, password: password }).then(getUserObject).catch(function (error) {
        logOut();
        throw new Error(error);
      });
    };

    var parseJson = function parseJson(json) {
      try {
        return JSON.parse(json);
      } catch (error) {
        return undefined;
      }
    };

    var authenticateUser = function authenticateUser(_ref6) {
      var cognitoUser = _ref6.cognitoUser,
          authenticationDetails = _ref6.authenticationDetails,
          password = _ref6.password;

      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      return new Promise(function (resolve, reject) {
        var onAuthSuccess = function onAuthSuccess(userSession) {
          var userObject = getUserObject(userSession);

          var _ref7 = parseJson(userObject.address.formatted) || {},
              _ref7$postal_code = _ref7.postal_code,
              postcode = _ref7$postal_code === undefined ? '' : _ref7$postal_code;

          var userId = userObject['cognito:username'];
          getGroupToken({ postcode: postcode, userId: userId }).then(function (groupToken) {
            setStvCookies({
              groupToken: groupToken,
              postcode: postcode,
              userId: userId,
              domain: '.' + currentDomain,
              secure: isSecureProtocol
            });
            resolve(userSession);
          }).catch(reject);
        };

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: onAuthSuccess,
          onFailure: function onFailure(err) {
            cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
            cognitoUser.initiateAuth(authenticationDetails, {
              onSuccess: function onSuccess(userSession) {
                changePassword(null, password, cognitoUser.email).then(onAuthSuccess).catch(reject);
              },
              onFailure: reject,
              customChallenge: function customChallenge() {
                cognitoUser.sendCustomChallengeAnswer(password, this);
              }
            });
          }
        });
      });
    };

    var logOut = function logOut() {
      if (process.browser && window.gigya) {
        window.gigya.accounts.logout({
          callback: loggedOutHandler
        });
      } else {
        loggedOutHandler();
      }
    };

    var loggedOutHandler = function loggedOutHandler() {
      var user = userPool.getCurrentUser();
      if (user) {
        user.signOut();
        removeStvCookies({
          domain: '.' + currentDomain
        });
      }
    };

    var getUser = function getUser() {
      var cognitoUser = getCognitoUserObject();
      if (cognitoUser) {
        return getUserSession(cognitoUser).then(function (session) {
          if (!session) {
            throw new Error();
          }
          return session.isValid() ? session : refreshUserSession({ user: cognitoUser, session: session });
        }).then(function (session) {
          return getUserObject(session);
        }).catch(function (e) {
        });
      }
      if (window.gigya) {
        return getUserFromGigya().then(performSessionMigration).then(function (user) {
          return user;
        }).catch(function (e) {
        });
      }
      return Promise.resolve(false);
    };

    var getUserFromGigya = function getUserFromGigya() {
      return new Promise(function (resolve, reject) {
        window.gigya.accounts.getAccountInfo({
          callback: function callback(info) {
            if (info && info.UID && info.profile) {
              resolve(info);
            } else {
              reject();
            }
          }
        });
      });
    };

    var updateUserDataMarketing = function updateUserDataMarketing(attributeList) {
      var cognitoUser = getCognitoUserObject();
      var newsLetters = attributeList;
      if (attributeList.Name === 'custom:newsletters') {
        newsLetters = JSON.parse(attributeList.Value);
      }
      return getUserSession(cognitoUser).then(function (userSession) {
        return fetch(poolData.apiGatewayUrl + 'users', {
          method: "PUT",
          body: JSON.stringify({
            AccessToken: userSession.accessToken.jwtToken,
            Newsletters: newsLetters
          })
        }).then(function (response) {
          if (response.status === 200) {
            return userSession;
          }
          throw new Error();
        }).then(function (session) {
          return refreshUserSession({
            user: cognitoUser,
            session: session
          });
        });
      });
    };

    var getCognitoUserObject = function getCognitoUserObject() {
      return userPool.getCurrentUser();
    };

    var getUserSession = function getUserSession() {
      var cognitoUserObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getCognitoUserObject();
      return new Promise(function (resolve, reject) {
        if (cognitoUserObject) {
          cognitoUserObject.getSession(function (err, session) {
            return resolve(session);
          });
        } else {
          reject();
        }
      });
    };

    var refreshUserSession = function refreshUserSession(_ref8) {
      var user = _ref8.user,
          session = _ref8.session;
      return new Promise(function (resolve, reject) {
        user.refreshSession(session.refreshToken, function (error, refreshedSession) {
          if (error) {
            reject(error);
            return;
          }
          var userObject = getUserObject(refreshedSession);

          var _ref9 = parseJson(userObject.address.formatted) || {},
              _ref9$postal_code = _ref9.postal_code,
              postcode = _ref9$postal_code === undefined ? '' : _ref9$postal_code;

          var userId = userObject['cognito:username'];

          getGroupToken({ postcode: postcode, userId: userId }).catch(function (error) {
            console.error(error);
            var currentGroupToken = Cookies.get('STV_GROUPTOKEN');
            if (currentGroupToken) {
              return currentGroupToken;
            }
            console.error('No groupToken found');
            return null;
          }).then(function (groupToken) {
            setStvCookies({
              postcode: postcode,
              userId: userId,
              groupToken: groupToken,
              domain: '.' + currentDomain,
              secure: isSecureProtocol
            });
            resolve(refreshedSession);
          });
        });
      });
    };

    var getUserObject = function getUserObject() {
      var session = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getUserSession();

      if (session) {
        var token = session.idToken && session.idToken.jwtToken;
        return token ? jwtDecode(token) : null;
      }
      return null;
    };

    var performSessionMigration = function performSessionMigration(_ref10) {
      var UID = _ref10.UID,
          profile = _ref10.profile,
          signature = _ref10.UIDSignature,
          timestamp = _ref10.signatureTimestamp;

      var formattedSignInObj = {
        email: profile && profile.email,
        password: '_migrate:' + Base64.encode([UID, signature, timestamp].join(','))
      };
      return signInUser(formattedSignInObj);
    };

    var signUpUser = function signUpUser(_ref11) {
      var emailValue = _ref11.emailValue,
          firstNameValue = _ref11.firstNameValue,
          lastNameValue = _ref11.lastNameValue,
          dobValue = _ref11.dobValue,
          postcodeValue = _ref11.postcodeValue,
          passwordValue = _ref11.passwordValue,
          _ref11$marketingValue = _ref11.marketingValue,
          marketingValue = _ref11$marketingValue === undefined ? false : _ref11$marketingValue,
          _ref11$sclValue = _ref11.sclValue,
          sclValue = _ref11$sclValue === undefined ? false : _ref11$sclValue;

      var getRegSource = function getRegSource() {
        var _getQueryString = getQueryString(),
            siteAction = _getQueryString.siteAction,
            source = _getQueryString.source;

        return siteAction === 'remoteLogin' ? source : window.location.href;
      };
      var attributeList = [];
      var dataEmail = {
        Name: 'email',
        Value: emailValue
      };
      var dataFirstName = {
        Name: 'given_name',
        Value: firstNameValue
      };
      var dataLastName = {
        Name: 'family_name',
        Value: lastNameValue
      };
      var dataDOB = {
        Name: 'birthdate',
        Value: dobValue.split('/').reverse().join('-')
      };
      var dataPostcode = {
        Name: 'address',
        Value: JSON.stringify({
          postal_code: postcodeValue
        })
      };
      var dataMarketing = {
        STV_OptedInEmail: marketingValue,
        STV_NL_Lotto3rdParty: sclValue
      };

      var dataRegSource = {
        Name: 'custom:registrationSource',
        Value: getRegSource()
      };
      var dataAdvertising = { Name: 'custom:targetedAdvertising', Value: 'true' };

      var attributeEmail = new CognitoUserAttribute(dataEmail);
      var attributeFirstName = new CognitoUserAttribute(dataFirstName);
      var attributeLastName = new CognitoUserAttribute(dataLastName);
      var attributeDOB = new CognitoUserAttribute(dataDOB);
      var attributePostcode = new CognitoUserAttribute(dataPostcode);
      var attributeRegSource = new CognitoUserAttribute(dataRegSource);
      var attributeAdvertising = new CognitoUserAttribute(dataAdvertising);

      attributeList.push(attributeEmail, attributeFirstName, attributeLastName, attributeDOB, attributePostcode, attributeRegSource, attributeAdvertising);

      return fetch(poolData.apiGatewayUrl + 'users', {
        method: 'POST',
        body: JSON.stringify({
          Password: passwordValue,
          ClientId: poolData.ClientId,
          UserAttributes: attributeList,
          Newsletters: dataMarketing
        })
      }).then(function (response) {
        return response.json();
      }).then(function (response, error) {
        if (response && response.results.UserConfirmed) {
          return signInUser({ email: emailValue.toLowerCase(), password: passwordValue });
        }
        throw new Error(error);
      });
    };

    var deleteUser = function deleteUser() {
      var cognitoUser = getCognitoUserObject();
      return getUserSession(cognitoUser).then(function (userSession) {
        return fetch(poolData.apiGatewayUrl + 'users', {
          method: 'DELETE',
          body: JSON.stringify({
            AccessToken: userSession.accessToken.jwtToken
          })
        }).then(function (response) {
          if (response.status === 200) {
            logOut();
            return true;
          }
          throw new Error();
        });
      });
    };

    var updateUser = function updateUser(changedAttributesObj) {
      var cognitoUser = getCognitoUserObject();
      return getUserSession(cognitoUser).then(function (userSession) {
        var attributeList = Object.keys(changedAttributesObj).map(function (key) {
          if (key === 'address') {
            return {
              Name: key,
              Value: JSON.stringify({
                postal_code: changedAttributesObj[key]
              })
            };
          }
          return {
            Name: key,
            Value: changedAttributesObj[key]
          };
        });

        return fetch(poolData.apiGatewayUrl + 'users', {
          method: 'PUT',
          body: JSON.stringify({
            AccessToken: userSession.accessToken.jwtToken,
            UserAttributes: attributeList
          })
        }).then(function (response) {
          if (response.status === 200) {
            return userSession;
          }
          throw new Error();
        });
      }).then(function (session) {
        return refreshUserSession({ user: cognitoUser, session: session });
      });
    };

    var sendResetPasswordEmail = function sendResetPasswordEmail(email) {
      return new Promise(function (resolve, reject) {

        var cognitoUser = new CognitoUser({
          Username: email,
          Pool: userPool
        });
        cognitoUser.forgotPassword({
          onSuccess: function onSuccess() {
            resolve({
              cognitoUser: CognitoUser
            });
          },
          onFailure: reject
        });
      });
    };

    var changePassword = function changePassword(password, newPassword, emailValue) {
      var cognitoUser = getCognitoUserObject();
      return getUserSession(cognitoUser).then(function (userSession) {
        var body = {
          AccessToken: userSession.accessToken.jwtToken,
          ProposedPassword: newPassword
        };
        if (password) {
          body.PreviousPassword = password;
        }

        return fetch(poolData.apiGatewayUrl + 'users/password/', {
          method: 'PUT',
          body: JSON.stringify(body)
        }).then(function (response) {
          if (response.status === 200) {
            return userSession;
          }
          throw new Error();
        }).then(function (session) {
          return refreshUserSession({ user: cognitoUser, session: session });
        });
      });
    };

    var resetPassword = function resetPassword(username, password, error) {
      var _getQueryString2 = getQueryString(),
          code = _getQueryString2.code;

      if (!code) {
        throw new Error();
      }
      var newPassword = password;
      return fetch(poolData.apiGatewayUrl + 'users/password/', {
        method: 'PUT',
        body: JSON.stringify({
          ClientId: poolData.ClientId,
          Password: newPassword,
          ConfirmationCode: code,
          Username: username
        })
      }).then(function (response) {
        if (response.status === 200) {
          return;
        }
        throw new Error();
      });
    };

    return {
      signInUser: signInUser,
      authenticateUser: authenticateUser,
      logOut: logOut,
      getUser: getUser,
      getUserFromGigya: getUserFromGigya,
      getCognitoUserObject: getCognitoUserObject,
      getUserSession: getUserSession,
      refreshUserSession: refreshUserSession,
      getUserObject: getUserObject,
      performSessionMigration: performSessionMigration,
      signUpUser: signUpUser,
      deleteUser: deleteUser,
      updateUser: updateUser,
      sendResetPasswordEmail: sendResetPasswordEmail,
      changePassword: changePassword,
      resetPassword: resetPassword,
      apiGatewayUrl: poolData.apiGatewayUrl,
      updateUserDataMarketing: updateUserDataMarketing,
      getGroupToken: getGroupToken
    };
  };

  return LoginService;

})));
