import { Component } from '../common/stv';

export default class PasswordReveal {

    componentHasMounted() {
        const { root } = this.elements;
        const $passwordField = root.querySelector('[password-field]');
        const $passwordRevealButton = root.querySelector('[password-reveal-button]');
        const $showText = root.querySelector('[password-reveal-show]');
        const $hideText = root.querySelector('[password-reveal-hide]');

        $passwordRevealButton.addEventListener('click', () => {
            this.toggleButtonText($showText);
            this.toggleButtonText($hideText);
            this.togglePasswordReveal({ $passwordField });
            $passwordField.focus();
        });
    }

    toggleButtonText($buttonText) {
        $buttonText.classList.toggle('hide');
    }

    togglePasswordReveal({ $passwordField }) {
        const inputType = $passwordField.getAttribute('type');
        $passwordField.setAttribute(
            'type',
            inputType === 'password'
            ? 'text'
            : 'password'
        );
    }

}

Component({ name: 'password-reveal' })(PasswordReveal);
