/* global Modernizr STV */
/* ### Common */
import { Service, getComponent, buildFetchPostObject, delegate, arraySelector, parseQueryString, getFirstValueFromObject, paramSerialiser, fetchHeaders } from './common/stv';
import { getCurrentDomain } from './common/cookies';
import './common/polyfills/requestanimationframe.js';

/* ### Site navigation */
import SiteNav                      from './modules/sitenav';
import NetworkNav                   from './modules/networknav';
import StickyNav                    from './modules/stickynav';

/* ### Account and Login */
import LoginService                 from './account/loginservice';
import AccountPanel                 from './account/accountpanel';

/* ### Forms */
import validatedfield               from './modules/validatedfield';
import ValidatedForm                from './modules/validatedform';
import AddressFinder                from './account/addressfinder';
import ValidationError              from './modules/validationerror';
import PasswordReveal               from './modules/password-reveal';

/* ### Modals */
import StaticModalGroup             from './modules/staticmodalgroup';
import StaticModal                  from './modules/staticmodal';

/* ### Misc site components */
import StvComscore                  from './modules/stv-comscore';
import VerificationBar              from './account/verificationbar';

/* ### 3rd party */
import animate                      from './../../libs/velocity.min';
import attachFastClick              from './../../libs/fastclick.js';
import CompEntryFormInit            from './modules/competitionentry';
import LoginModalsInit              from './modules/loginmodals';
/* Mobile device URI's for opening apps instead of pages */
import stvSocialMobile              from './modules/stv-social-mobile';
// share modals
import SharedItem from './modules/shared-item';

import createCarousel               from './modules/carousel';
import initCookieBanner             from './common/cookie-banner';

/* ### App initialization */
@Service
class MetropolisApplication {
    constructor() {
        /* ### TODO broadcaster needs to work for future story */
        const videoPlayers = arraySelector('[data-widgettype=bcPlayer]');
        const currentDomain = getCurrentDomain();
        // path to override videoplaza shares / any tracking
        const shares = 'vp_stv.tv';
        // vpcategory is the path e.g /news/scotland
        const pathNode = document.querySelector('[data-path]');
        const path = pathNode ? `/${pathNode.getAttribute('data-path')}` : '';
        // vptags is article id
        const idNode = document.querySelector('[data-id]');
        const id = idNode ? idNode.getAttribute('data-id') : '';
        // launch social mobile
        const replaceSocialURI = stvSocialMobile();
        const sharedItem = SharedItem(window.location.href);

        if (Modernizr.touchevents) {
            attachFastClick(document.body);
        }

        CompEntryFormInit();
        LoginModalsInit();
        CarouselInit();
        initCookieBanner();
    }
}

function CarouselInit() {
    const carousels = Array.from(document.querySelectorAll('.js-carousel'));

    carousels
        .forEach(carousel => {

            const spacing = parseInt(carousel.getAttribute('data-spacing')) || 0;

            createCarousel({
                spacing,
                parent: carousel,
                wrapper: carousel.querySelector('.js-carousel-wrapper'),
                items: Array.from(carousel.querySelectorAll('.js-carousel-item')),
                leftButton: carousel.querySelector('.js-carousel-right'),
                rightButton: carousel.querySelector('.js-carousel-left'),
            });

        });
}
