
const errorCodes = {
    fieldsDoNotMatch: 1001,
    fieldIsRequired: 1002,
    fieldValidationFailed: 1003,
    agerestriction: 1004,
    serverValidationError: 400003,
    invalidEmailOrPassword: 403042,
    noUserWithThisEmailAddress: 403047,
    passwordsCannotMatch: 400006,

    getIndex(code) {
        for (let index in this) {
            if (this[index] === code) return index;
        }
    }
}

export default errorCodes;
