/**
 * # Label Aliases

 * ## articleTitle
 * ### Comscore Label - stv_artcl_title
 * Used to record the headline of a story being viewed
 * @example articleTitle : 'Good News'


 * ## articleID
 * ### Comscore Label - stv_artcl_id
 * Used to record the headline ID of a story being viewed
 * @example articleID : '9999'


 * ## articleAuthor
 * ### Comscore Label - stv_artcl_author
 * Used to record the original author of a story being viewed
 * @example articleAuthor : 'C Loud'


 * ## articleType
 * ### Comscore Label - stv_artcl_type
 * Used to record the type, such as, normal, blog, etc.
 * @example articleType : 'Blog'


 * ## articleSource
 * ### Comscore Label - stv_artcl_source
 * Used to set whether the content belongs to STV or is curated by a partner
 * @example articleSource : 'STV'


 * ## articleDate
 * ### Comscore Label - stv_artcl_date
 * Used to record the original publication date and time of a story being
 * viewed. UNIX timestamp value should be used if possible.
 * @example articleDate : 'January 1st, 2016'


 * ## articleModified
 * ### Comscore Label - stv_artcl_update
 * Used to record the updated publication date and time of a story being
 * viewed. UNIX timestamp value should be used if possible.
 * @example articleModified : 'January 2nd, 2016'


 * ## articleTheme
 * ### Comscore Label - stv_artcl_theme
 * Will have defined themes such as "Life", "Entertainment" etc. These are
 * not sections, rather themes that can be associated with any content to
 * allow us to target (and sell) display advertising as bundles. Multiple values
 * can be added by using the pipe delimiter.
 * @example articleTheme : 'Opinion'


 * ## userLocation
 * ### Comscore Label - stv_loc
 * The current location should be passed with every measurement. This allows us to, at any point,
 * compare the set location with the actual location (as derived by DAx).
 * gw – for Glasgow & West
 * ee – for Edinburgh & East
 * an – for Aberdeen & North
 * dt – for Dundee & Tayside
 * c – for central
 * hi – for Highlands & Islands
 * @example userLocation : 'gw'


 * ## userSectionOrder
 * ### Comscore Label - stv_sections
 * Whenever a user reorders the available sections, pass the following label with a delimited list of
 * values indicating the currently selected order e.g. 'sc|uk|in|po|sp|fe|en|we'
 * @example userSectionOrder : 'sc|uk|in|po|sp|fe|en|we'


 * ## isLoggedIn --Persistency is mandatory
 * ### Comscore Label - stv_li
 * Is the user logged in or not? 1 for yes. 2 for yes, via smart login. 0 for no
 * @example isLoggedIn : 'yes'


 * ## userID --Persistency is mandatory when logged in
 * ### Comscore Label - stv_gigya_id
 * The user id
 * @example userID : '1234'

 * ## eventType
 * ### Comscore Label - stv_ev_ty
 * The type of event that occurred or link that was clicked. For example, social, nav
 * @example eventType : 'registration'


 * ## eventAction
 * ### Comscore Label - stv_ev_ac
 * The action that occurs when the event takes place or link is clicked. For registration this is 'success' or 'fail'
 * @example eventAction : 'success'


 * ## placement
 * ### Comscore Label - stv_ev_pl
 * The placement on screen/site where the event occurred or where the link resides. For example, footer, carousel or top-menu.
 * @example placement : 'top-menu'


 * ## destination
 * ### Comscore Label - stv_ev_as
 * The destination or asset of the link or event. This could be a URL or site name in relation to a nav link, or it could
 * be a field name or attribute in association with a form. For instance: facebook.com.
 * @example destination : 'registration-success'


 * ## eventMethod
 * ### Comscore Label - stv_ui
 * Navigation or interaction method. swipe, click, pinch, pull-up, etc.
 * @example eventMethod : 'click'


 * ## filledInFields
 * ### Comscore Label - stv_ff
 * Currently filled in form fields at time of form abandonment e.g. 'fn|ln|ed|p'
 * fn - First name
 * ln - Last name
 * ed - Email address
 * p - Password
 * @example filledInFields : 'fn|ln|ed|p'

 * ## location
 * ### Comscore Label - stv_wloc
 * Weather location as set by user e.g. edinburgh
 * @example location : 'Glasgow'

 */

const LABELS = {
    articleTitle : 'stv_artcl_title',
    articleID : 'stv_artcl_id',
    articleAuthor : 'stv_artcl_author',
    articleType : 'stv_artcl_type',
    articleSource : 'stv_artcl_source',
    articleDate : 'stv_artcl_date',
    articleModified : 'stv_artcl_update',
    articleTheme : 'stv_artcl_theme',
    userLocation : 'stv_loc',
    userSectionOrder : 'stv_sections',
    isLoggedIn : 'stv_li',
    userID : 'stv_gigya_id',
    eventType : 'stv_ev_ty',
    eventAction : 'stv_ev_ac',
    placement : 'stv_ev_pl',
    destination : 'stv_ev_as',
    eventMethod : 'stv_ui',
    filledInFields : 'stv_ff',
    location : 'stv_wloc',
    currentSite : 'currentsite',
    screenWidthHeight : 'ns_st_cs',
    // Culled 04/11/2015 after Comscore StreamSense feedback - referrer : 'ns_referrer',
    adBlockerEnabled : 'stv_adblocker'
};

const requiredRegistrationLabels = [
    'eventType',
    'eventAction',
    'eventMethod',
    'userLocation',
    'isLoggedIn',
    'userID'
];

const requiredRegistrationAbondonedLabels = [
    'filledInFields',
    'userLocation',
    'isLoggedIn'
];

const requiredSocialShareLabels = [
    'eventType',
    'eventAction',
    'placement',
    'destination',
    'eventMethod',
    'userLocation',
    'isLoggedIn'
];

const requiredWeatherLocationLabels = [
    'location',
    'userLocation',
    'isLoggedIn'
];

const requiredLoggedInLabels = [
    'eventType',
    'eventAction',
    'eventMethod',
    'userID',
    'userLocation',
    'isLoggedIn'
];

const requiredScrollLabels = [
    'eventType',
    'eventAction',
    'placement',
    'eventMethod',
    'userLocation',
    'isLoggedIn'
]

const requiredClickLabels = [
    'isLoggedIn'
];

export default class STVComscorePlugin {

    _udm_ (url) {
        // console.log(url);
        udm_(url);
    }

    _warn (message) {
        console.warn(message);
    }


    isValidComscoreUrl (url) {
        let regex = /https?\:\/\/uk.sitestat.com\/stv\/.+\/s\?/;
        return regex.test(url);
    }


    convertLabelsToComscore (labels) {
        let comscoreLabels = {};
        for (let prop in labels) {
            if (LABELS.hasOwnProperty(prop) && labels[prop] !== undefined && labels[prop] !== '') {
                comscoreLabels[LABELS[prop]] = labels[prop];
            }
        }
        return comscoreLabels;
    }


    serialiseParams (params) {
        let pairs = [];
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                pairs.push(`${key}=${params[key]}`);
            }
        }

        return encodeURI(pairs.join('&'));
    }


    hasRequiredLabels (requiredLabels, labels) {
        let labelsMatched = 0;

        requiredLabels.forEach(requiredLabel => {
            let matched = false;
            for (let labelProp in labels) {
                if (labelProp == requiredLabel) {
                    if (labelProp == 'isLoggedIn' && labels.isLoggedIn == true && !labels.userID) {
                        this._warn('The isLoggedIn label is true but there is no userID associated with it');
                    }
                    matched = true;
                    labelsMatched++;
                }
            }
            if (!matched) {
                this._warn(`Missing a required label: ${requiredLabel}`);
            }
        });

        return labelsMatched === requiredLabels.length;
    }


    daxPageMeasurements (url, labels) {
        labels = labels || {};
        let comscoreUrl = this.buildComscoreUrl(url, labels);

        this._udm_(comscoreUrl);
    }


    buildComscoreUrl (url, labels) {
        if (url) {

            let comscoreLabels = this.serialiseParams(this.convertLabelsToComscore(labels));
            let comscoreUrl = `${url}&${comscoreLabels}`;

            if (this.isValidComscoreUrl(comscoreUrl)) {
                return comscoreUrl;
            } else {
                this._warn(`${url} is invalid. It should resemble http://uk.sitestat.com/stv/[sitename]/s?name=[pagename]&[standard & custom labels]`);
            }
        } else {
            this._warn('Cannot make Comscore call because you are missing the url argument');
        }
    }


    daxRegistration (url, labels) {

        if (this.hasRequiredLabels(requiredRegistrationLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }
    }


    daxRegistrationAbandoned (url, labels) {

        if (this.hasRequiredLabels(requiredRegistrationAbondonedLabels , labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }

    }


    daxLoggedIn (url, labels) {

        if (this.hasRequiredLabels(requiredLoggedInLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }

    }


    daxWeatherLocation (url, labels) {

        if (this.hasRequiredLabels(requiredWeatherLocationLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }

    }

    daxSocialShare (url, labels) {

        if (this.hasRequiredLabels(requiredSocialShareLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }
    }


    daxScrolled (url, labels) {

        if (this.hasRequiredLabels(requiredScrollLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }
    }

    daxClicked (url, labels) {

        if (this.hasRequiredLabels(requiredClickLabels, labels)) {

            let comscoreUrl = this.buildComscoreUrl(url, labels);

            this._udm_(`${comscoreUrl}&ns_type=hidden`);
        }
    }

}
