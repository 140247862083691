'use strict';
/**
 * ## StickyNav
 * Hides the nav when scrolling down, and unhides on scroll up
 */

import { Component, Broadcaster } from '../common/stv';

/**
 * @class StickyNav
 */
@Component({
    name: 'sticky-nav',
    elements: {
        subMenu: '.news-nav__nav-item--has-sub',
        mhAd: '.ad-placement-mh'
    }
})
export default class StickyNav {
    componentHasMounted() {
        // setTimeout auto binds "this" as the window, we don't want that
        const beginNavigation = () => {
            this.navigation();
        };
                // When dfp is initalised - as long as no AD BLOCK
        Broadcaster.on('dfp-masthead-initialised', function(status) {
            if (status.adsEnabled) {
                // for iOS - iOS won't fire the ad-rendered event due to a bug in DFP
                const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
                // iOS adblocker hides the frame, its still there but not visible
                const googleFrame = document.getElementById('mh').querySelector('#mh > iframe');
                const frameNotVisible = window.getComputedStyle(googleFrame).display === 'none';
                // If iOS and adblocker
                if (iOS && frameNotVisible) {
                    // start navigation
                    beginNavigation();
                    return;
                }
                // everything else
                // begin sticky navigaion after ad viewablitiy time out
                Broadcaster.on('ad-rendered', function(ad) {
                    // if we have an ad slot returned
                    if (ad.slot && ad.slot.context.getAttribute('stv-ad') === 'mh') {
                            const iframe = ad.slot.context.getElementsByTagName('iframe')[0];
                            // ad content has loaded
                            iframe.onload = function () {
                                // begin regular navigation after set time
                                setTimeout(beginNavigation, 3500);
                            };
                    }
                });
            // ads are blocked continue with regular navigation
            } else {
                beginNavigation();
            }
        });
        // If ads are blocked
        Broadcaster.on('ads-blocked', function() {
            beginNavigation();
        });
    }
    navigation() {
        // Dom Elements
        const stickyNav = this.elements.root;
        const subMenu = this.elements.subMenu[0];
        const mhAd = this.elements.mhAd[0];
        const body = document.querySelector('body');
        // Scrolling variables
        let scrollY = window.pageYOffset;
        // direction for logic
        let direction;
        // scrolling enabled
        let scrollingEnabled = true;
        // update function for requestAnimationFrame
        const update = () => {
            const yPosition = window.pageYOffset;
            const isScrollingDown = yPosition > scrollY;
            const isScrollingUp = yPosition < scrollY;
            const stationary = yPosition === scrollY;
            // Is scrolling enable
            scrollingEnabled = !body.classList.contains('no-scroll');
            // hide display ad page is below it's height
            (yPosition > mhAd.offsetHeight) ? mhAd.classList.add('is-out-of-view') : mhAd.classList.remove('is-out-of-view');
            // If we're scrolling up show the navbar
            if (isScrollingUp) {
                direction = 'up';
                // show the nav
                stickyNav.classList.remove('is-out-of-view');
            }
            // if we're scrollling down hide the navbar
            if (isScrollingDown) {
                direction = 'down';
                // scroll ad back up after certain scroll amount of pixels (half the ad height)
                if (yPosition > mhAd.clientHeight) {
                    // hide submenu if it's open
                    subMenu.classList.remove('is-open');
                    // hide the nav if
                    stickyNav.classList.add('is-out-of-view');
                }
            }
            // if we're stationary and previous direction was up / or at the top
            if (stationary && direction === 'up' || stationary && !yPosition) {
                // recent interaction
                stickyNav.classList.remove('is-out-of-view');
            }
            // update values if scrolling enabled otherwise, no-scroll puts 0 as pageYOffset
            if (scrollingEnabled) {
                 scrollY = yPosition;
            }
            // For performance optimisations use requestAnimationFrame in lieu of window 'scroll' even
             requestAnimationFrame(update);
        };
        // start requesting Animation Frame
        update();
    }
}