
import { Component, Broadcaster, Implements, throttle, setStyle } from "../common/stv";
import animate from "../../../libs/velocity.min";
import { windowSize } from './window-size';

const CLOSED            = false;
const OPENED            = true;
const INITIAL_STYLES    = { position: 'absolute', visibility: 'hidden', opacity: 0, willChange: 'opacity, transform' };

@Component({
    name: 'static-modal'
})
@Implements(Broadcaster)
export default class StaticModal {
    state       = CLOSED;

    constructor() {
        this.options = {
            animDuration: 500,
            shadeStyle: 'fill',
            urlControllable: false
        };
    }

    componentHasMounted() {
        let win = window;
        win.addEventListener('resize', throttle(this.reposition, 100, this));
        setStyle(this.elements.root, INITIAL_STYLES);
        this.reposition();
    }

    open() {
        return new Promise((resolve, reject) => {
            if (this.state === OPENED) resolve();

            let root = this.elements.root;
            this.reposition(true);

            requestAnimationFrame(animate
                .bind(this, root, {opacity: 1}, {
                    duration: this.options.animDuration,
                    visibility: "visible",
                    complete: () => {
                        this.broadcast('modal-open-after');
                        resolve();
                    }
                }));
            this.broadcast('modal-open-before');
            this.state = OPENED;

        });
    }

    close() {
        return new Promise((resolve, reject) => {
            if (this.state === CLOSED) resolve();

            let root = this.elements.root;

            requestAnimationFrame(animate
                .bind(this, root, {opacity: 0}, {
                    duration: this.options.animDuration,
                    visibility: "hidden",
                    complete: () => {
                        this.broadcast('modal-close-after');
                        resolve();
                    }
                })
            );
            this.broadcast('modal-close-before');
            this.state = CLOSED;
        });
    }

    reposition(forceViewportTop = true) {
        let viewport    = windowSize();
        let modal       = this._pollModalDimensions();
        let left        = (modal.width < viewport.width) ? parseInt( (viewport.width / 2) - (modal.width / 2) ) : 0;
        let top         = (modal.height < viewport.height) ? parseInt( (viewport.height / 2) - (modal.height / 2) ) : 0;
        let win         = window;

        if (forceViewportTop) {
            top += (win.scrollY || win.pageYOffset);
        }

        setStyle(this.elements.root, { top });
    }

    _pollModalDimensions() {
        let root = this.elements.root;
        return root.getBoundingClientRect();
    }
}
