
import { Broadcaster, Component, getComponent, Implements } from '../common/stv';

const CLASS_VALID   = 'is-valid';
const CLASS_INVALID = 'is-not-valid';

@Component({
    name: 'validated-form'
})
@Implements(Broadcaster)
export default class ValidatedForm {
    valid = false;
    validatedFields = [];
    locked = false;
    inputs = [];
    formLevelError;

    constructor() {
        this.options = {
            allowSubmission: true
        };
    }

    componentHasMounted() {
        this.validatedFields = [];
        this.validatedFields = this.validatedFields.concat(this.children.find('validated-field').map(item => item.instance));
        this.validatedFields = this.validatedFields.concat(this.children.find('file-upload').map(item => item.instance));

        this.validationErrors = this.children.find('validation-error').map(item => item.instance);
        this.inputs = Array.from(this.elements.root.querySelectorAll('input, textarea, select'));
        this.addListeners();
        this.validate();
    }

    lock() {
        this.locked = true;
    }

    unlock() {
        this.locked = false;
    }

    reset() {
        this.validatedFields.forEach(field => field.reset());
        this.validationErrors.forEach(err => err.reset());
    }

    percentComplete() {
        const total = this.validatedFields.length;
        const filled = this.validatedFields.filter(item => item.elements.root.value.length > 0).length;
        return parseInt((filled/total) * 100);
    }

    setFormLevelError(errorCode) {
        this.formLevelError = errorCode;
    }

    getFormState() {
        let formState = {};
        this.inputs.forEach(input => {
            if (input.name) formState[input.name] = input.type === 'checkbox' ? input.checked : input.value;
        });
        return formState;
    }

    addListeners() {
        this.validatedFields.forEach(field => field.on('validation', this.validate.bind(this)));
        $(this.elements.root).on('submit', this.validateSubmission.bind(this));
    }

    validateSubmission(evt) {
        this.validatedFields.forEach(field => field.validate());
        if (!this.valid || !this.options.allowSubmission || this.locked) evt.preventDefault();
        if (this.locked) return;
        this.broadcast(this.valid ? 'submission' : 'invalid-submission', this.getFormState());
    }

    validate() {
        let validationState = {};
        if (this.formLevelError) {
            validationState.errorCode = this.formLevelError;
            this.valid = false;
            this.formLevelError = undefined;
        } else {
            let succesfullyValidatedFields = 0;
            this.validatedFields.forEach(field => {
                if (field.valid) succesfullyValidatedFields++;
            });
            this.valid = (succesfullyValidatedFields === this.validatedFields.length);
        }

        validationState.valid = this.valid;

        this.broadcast('validation', validationState);
        this.updateDOM();
    }

    updateDOM() {
        // TODO do we use this for anything??
        // this.elements.root.classList
        //     .add( this.valid ? CLASS_VALID : CLASS_INVALID );
        // this.elements.root.classList
        //     .remove( !this.valid ? CLASS_VALID : CLASS_INVALID );
    }
}
