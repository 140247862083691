'use strict';

/**
 * ## NetworkNav
 * Sets up the toggles for the mobile view of network-nav.
 * Only one menu can be open at once
 */

import { Component } from '../common/stv';
import { arraySelector } from '../common/stv';

const mobileNavToggleAttr = 'stv-network-nav-target';
const mobileMenuOpenClass = 'is-open';

/**
 * @class NetworkNav
 */
@Component({
    name: 'stv-network-nav',
    elements: {
        subNavToggle: `[${mobileNavToggleAttr}]`,
    }
})
export default class NetworkNav {
    componentHasMounted() {
        this.bindEvents();
    }

    /**
     * binds action to menu toggles
     * @method bindEvents
     */
    bindEvents() {
        this.elements.subNavToggle.forEach((subNavToggle) => {
            subNavToggle.addEventListener('click', (e) => {
                e.preventDefault();
                let toggleType = subNavToggle.classList.contains(mobileMenuOpenClass) ? 'remove' : 'add';

                this.toggleAllOff();
                this.changeTarget(subNavToggle, toggleType);
            })
        })
    }

    /**
     * Closes all menues
     * @method toggleAllOff
     */
    toggleAllOff() {
        this.elements.subNavToggle.forEach((subNavToggle) => {
            this.changeTarget(subNavToggle, 'remove')
        })
    }

    /**
     * Opens or closes menu
     * @method changeTarget
     * @param {Node} target menu button with menu-target attribute
     * @param {String} type must be 'add' or 'remove'. 
     */
    changeTarget(target, type) {
        let targetSelector = target.getAttribute(mobileNavToggleAttr);

        target.classList[type](mobileMenuOpenClass);
        arraySelector(`[${targetSelector}]`).forEach((target) => {
            target.classList[type](mobileMenuOpenClass);
        })
    }
}