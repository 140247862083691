"use strict";

/**
 * ## Window Size
 * Cross-browser window sizes with optional scrollbar size support
 */

/**
 * @function getScrollbarWidth
 * Gets the width of scrollbars on screen. 
 * @returns {Number} 0 if no scrollbars, usually the standard 15 if scrollbars are present
 */
let scrollbarWidth = null;
export function getScrollbarWidth() {
    function _getScrollbarWidth() {
        let d = document;
        let outer = d.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

        d.body.appendChild(outer);

        let widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        let inner = d.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);        

        let widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        scrollbarWidth = widthNoScroll - widthWithScroll;
        return scrollbarWidth;
    }

    /* Since this takes a bit of processing to figure out,
     * only run it once, store it in a variable, then return 
     * that consequentially
     */
    return (() => {
        if (scrollbarWidth === null) {
            return _getScrollbarWidth();
        } else {
            return scrollbarWidth;
        }
    })();
};


/**
 * @function windowSize
 * Gets the current window size
 * @param {Boolean} includeSidebar includes the scrollbar size in width value 
 * @returns {Object} with width and height values
 */
export function windowSize(includeSidebar = false){
    let d = document,
        g = d.getElementsByTagName('body')[0],
        x = d.documentElement.clientWidth || g.clientWidth,
        y = d.documentElement.clientHeight|| g.clientHeight;

    return {
        width: x + (includeSidebar ? getScrollbarWidth() : 0),
        height: y
    }
}

