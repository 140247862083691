'use strict';

import { getCurrentDomain }             from '../common/cookies';
import loginService from '../account/initLoginService';
import { getComponent, fetchHeaders, Broadcaster }   from '../common/stv';

const home = document.querySelector('.page-url-path-home');
const winners = document.querySelector('.page-url-path-winners');
const competitions = document.querySelector('.js-competition-list');
const competitionsWinnerPage = document.querySelector('.js-competition-list-winners');
const $competitionContainer = document.querySelector('#competition__container');
let loginID;

export function updateCompetitionList(text) {
    winners ? (competitionsWinnerPage.innerHTML = text) : (competitions.innerHTML = text);
}

export function simpleFetch() {
    const url = `/ajax/widgets/CompetitionList/`;

    return fetch(url, fetchHeaders())
    .then(response => response.text())
    .then(updateCompetitionList)
    .catch(error => console.log(error))
}

export function enteredCompetitionsFetch(userObject) {
    loginID = userObject['cognito:username'];

    const url = `/ajax/widgets/CompetitionList/?userId=${encodeURIComponent(loginID)}`;

    return fetch(url, fetchHeaders())
    .then(response => response.text())
    .then(updateCompetitionList);
}

/*
    Fetching a competition list on homepage only needs to happen at these points
    - arrive at page and logged in
    - login
    - logout

    Only need to fetch competition list on enter page when submit competition entry, that code exists in competitionentry/index.js
*/

// Below should only run on homepage and winner page

if (home || winners) {
    Broadcaster.on('log-in', () => {
        loginService
        .getUser()
        .then(enteredCompetitionsFetch)
        .catch(error => console.log(error));

    Broadcaster
        .on('log-out', simpleFetch);
    });
}

document.addEventListener("DOMContentLoaded", () => {

    const competitionModalGroup = getComponent('competition-entry-modal-group');

    const $winnerTriggers = Array.from(document.querySelectorAll('[data-winner-trigger]'));

    $winnerTriggers
        .forEach($trigger => {
            $trigger.addEventListener('click', event => {
                event.preventDefault();
                let modalId = event.target.getAttribute('data-id');
                competitionModalGroup.open(modalId);
            })
        }
    );

});
