import { getComponent, delegate, arraySelector, parseQueryString } from '../../common/stv';
import loginService from '../../account/initLoginService';
import animate from '../../../../libs/velocity.min';
import { getCurrentDomain } from '../../common/cookies';

function run() {
    const { siteName }                = window.STV;
    const state                       = {};
    const currentDomain               = getCurrentDomain();

    // components
    const signInModalComponent        = getComponent('sign-in-modal');
    const joinModalComponent          = getComponent('join-modal');
    const emailFormComponent          = getComponent('email-form--modal');
    const signInFormComponent         = getComponent('sign-in-form--modal');
    const joinFormComponent           = getComponent('join-form--modal');
    const registrationModalGroup      = getComponent('registration-modal-group');
    const signInFormEmailComponent    = getComponent('sign-in-form__email--modal');

    /* ### start of elements in scope */
    // login modal
    const $logInModal                 = signInModalComponent.elements.root;

    // email form
    const $emailForm                  = emailFormComponent.elements.root;
    const $emailSection               = $logInModal.querySelector('.stv-modal__email-section');
    const $emailFormEmail             = $emailForm.querySelector('#email-form__email--modal');

    // sign in form
    const $signInForm                 = signInFormComponent.elements.root;
    const $signInSection              = $logInModal.querySelector('.stv-modal__sign-in-section');
    const $signInFormEmail            = $signInForm.querySelector('#sign-in-form__email--modal');
    const $signInFormEmailLock        = $signInForm.querySelector('.sign-in__form-email-lock');
    const $signInFormEmailLabel       = $signInForm.querySelector('[for="sign-in-form__email--modal"]');
    const $signInFormWrongEmail       = $signInForm.querySelector('#sign-in-form__wrong-email--modal');

    // join form
    const $joinForm                   = joinFormComponent.elements.root;
    const $joinFormEmail              = $joinForm.querySelector('#join-form__email--modal');
    const $joinFormEmailLock          = $joinForm.querySelector('.join-form__email-lock');
    const $joinFormWrongEmail         = $joinForm.querySelector('#join-form__wrong-email--modal');

    // delete account form
    const $deleteAccountBtn           = document.querySelector('#delete-account-button');
    /* ### end of elements in scope */

    const formComponents              = [emailFormComponent, signInFormComponent, joinFormComponent]; // add any form components needing reset when user closes modals via X button or overlay click
    const urlEmailParam               = parseQueryString(window.location.href).email;
    state.$currentLogInSection        = $emailForm;

    if (urlEmailParam) {
        $emailFormEmail.value = urlEmailParam;
    }

    emailFormComponent
        .on('submission', () => {
            loginService
                .emailFormHandler({
                    emailFormComponent,
                    signInFormEmailComponent,
                    $signInFormEmailLabel,
                    $signInFormEmail,
                    $signInFormEmailLock,
                    $joinFormEmail,
                    $joinFormEmailLock,
                })
                .then(data => {
                    const { loginIdExists } = data;
                    if (loginIdExists) {
                        state.$currentLogInSection = switchLogInSections({
                            $currentSection: $emailSection,
                            $nextSection: $signInSection,
                            done: () => emailFormComponent.reset()
                        });
                    } else {
                        registrationModalGroup.open('join-modal');
                    }
                });
            });


    // Sign in form submit listener
    signInFormComponent
        .on('submission', () => {
            loginService
                .signInHandler({
                    signInFormComponent,
                    $signInForm
                })
                .then(() => {
                    signInFormComponent.reset();
                    registrationModalGroup.close();
                })
                .catch(err => loginService.handleFormErrors({ formComponent: signInFormComponent, $form: $signInForm, err }));
        });


    // Join form submit listener
    joinFormComponent
        .on('submission', formData => {
            loginService
                .joinHandler({
                    formData,
                    joinFormComponent,
                    currentDomain,
                })
                .then(() => registrationModalGroup.open('registration-welcome'))
                .then(() => joinFormComponent.broadcast('global-registration-success'));
        });


    // when user opens the sign in modal, load the marketing image so it displays instantly on the join modal
    signInModalComponent
        .on('modal-open-before', () => getMarketingImage({ siteName, viewPortWidth: document.body.clientWidth }));


    // after sign in modal is hidden, reset the email and sign in forms
    signInModalComponent
        .on('modal-close-after', () => {
            $emailFormEmail.blur();
            emailFormComponent.reset();
            signInFormComponent.reset();
        });


    joinModalComponent
        .on('modal-close-after', () => {
            joinFormComponent.reset();
        });

    $joinFormWrongEmail
        .addEventListener('click', () => registrationModalGroup.open('sign-in-modal'));

    // reset all forms when a user clicks the modal overlay
    registrationModalGroup
        .on('shade-click', () => registrationModalGroup.close()
            .then(() => resetAllForms({
                formComponents,
                $emailSection,
                signInSections,
                state
            })
        ));


    // reset all forms when a user signs in (do this after 1 second so it happens
    // after the modal has faded out, preventing a flash of the modal's initial state)


    // launch the sign in process when a user clicks sign in/join
    document.body
        .addEventListener('click', delegate('[data-launch-registration]', event => registrationModalGroup
            .open(event.target.getAttribute('data-modal-id'))));


    // log the user out when they click the log out button
    document.body
        .addEventListener('click', delegate('[data-log-out]', event => logOutControl({
            event
        })));


    document.body
        .addEventListener('click', delegate('[data-resend-verification]', () => account
            .resendVerificationCode()
                .then(() => registrationModalGroup.open('registration-verification-resent'))
        ));


    // reset all forms when a user clicks X on the modals
    const regModalCloseBtns = Array.from(registrationModalGroup.elements.root.querySelectorAll('[data-close-modal]'));
    regModalCloseBtns
        .forEach(el => {
            el.addEventListener('click', () => registrationModalGroup.close().then(() => {
                resetAllForms({
                    formComponents,
                    $emailSection,
                    signInSections,
                    state
                });
            }));
        });


    arraySelector('[data-launch-section]').forEach(el => {
        // TODO make a function
        const $nextSection = document.querySelector(el.getAttribute('data-launch-section'));
        el.addEventListener('click', () => state.$currentLogInSection = switchLogInSections({
            $currentSection: state.$currentLogInSection,
            $nextSection,
            done: () => {
                emailFormComponent.reset();
            }
        }));
    });


    $signInFormWrongEmail
        .addEventListener('click', () => state.$currentLogInSection = switchLogInSections({
            $currentSection: state.$currentLogInSection,
            $nextSection: $emailSection
        }));

    // Prevent double click on the delete account button
    const reEnableDeleteButton = $deleteAccountBtn
        ? disableOnClick($deleteAccountBtn)
        : undefined;
}

function getMarketingImage({ siteName, viewPortWidth }) {
    let imageSize = 'medium';

    function getImageColour() {
        switch (siteName) {
            case 'competitions':
                return 'light';
            case 'player':
                return 'orange';
            default:
                return 'blue';
        }
    }

    if (viewPortWidth > 480) {
        imageSize = 'large';
    }
    new Image().src = `/static/source/images/${getImageColour()}-triangle-bg--${imageSize}.jpg`;
}


function logOutControl({ account, event }) {
    event.preventDefault();
}


function switchLogInSections({ $currentSection, $nextSection, done }) {
    requestAnimationFrame(() => {
        animate($currentSection, 'fadeOut', { duration: 200 })
            .then(() => {
                $currentSection.classList.add('hide');
                $nextSection.style.opacity = 0;
                $nextSection.classList.remove('hide');
                animate($nextSection, 'fadeIn', { duration: 200 })
                    .then(() => {
                        [$currentSection, $nextSection].forEach(el => el.removeAttribute('style')); // clean up and remove the style attributes now we're finished
                        if (done) {
                            done();
                        }
                    });
            });
    });
    return $nextSection;
}


function resetAllForms({ formComponents, $emailSection, signInSections, state }) {
    formComponents.forEach(form => form.reset());
    $emailSection.classList.remove('hide');
    signInSections.forEach(section => section.classList.add('hide'));
    state.$currentLogInSection = $emailSection;
}


export default function() {
    if (['interactive'].includes(document.readyState) && document.body) {
        run();
    } else {
        document.addEventListener('DOMContentLoaded', run, false);
    }
}

