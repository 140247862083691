import { UAParser } from '../../../libs/ua-parser';

export default function stvSocialMobile(content) {
		// Use the UA Parser to test for device type
		const _UAParser = UAParser.UAParser();
		// Unwrap device from _UAParser instance
		const { device } = _UAParser;
		// Determine if this is a device
		const isDevice = device.type ? true : false;
		// replace standard desktop links with app uri instruction
		const replaceLinks = (elementList) => {
			if (elementList) {
				// Convert NodeArray to Array
				const nodesAsArray = [].slice.call(elementList);
				// For each in the nodes array
				nodesAsArray.forEach((element) => {
					// Set the correct uri
					element.setAttribute('href', element.getAttribute('stv-social-mobile-uri'));
				});
			}
		};
		// If we're a mobile device
		if (isDevice && device.type === 'mobile') {
			// Grabe the URI that will replace the desktop href
			const element = content || document;
			const $socialMobileEls = element.querySelectorAll('[stv-social-mobile-uri]');
			// Do the replacing
			replaceLinks($socialMobileEls);
		}
}
