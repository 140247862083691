'use strict';

import STVComscorePlugin from './stv-comscore-plugin';
import { Implements, getComponent, Broadcaster, Service, debounce, delegate } from './../common/stv';

const comscore = new STVComscorePlugin();
const screenWidthHeight = (screen.width+'x'+screen.height);
const referrer = document.referrer;



@Implements(Broadcaster)
@Service
export default class StvComscore {

    constructor() {

        this.weatherComponent = getComponent('weather-component');
        this.joinFormComponent = getComponent('join-form--modal');
        this.joinModalComponent = getComponent('join-modal');
        this.initialPageLabels = window.STV.comscoreTags.url;
        this.userID = 'userNotLoggedIn';
        this.isLoggedIn = 0;
        this.adBlockerEnabled = this.isUsingAdBlocker();
    }

    updateUserDataLabels (userData) {
        this.userID = userData.UID === undefined ? 'userNotLoggedIn' : userData.UID;
        this.isLoggedIn = this.userID !== undefined ? 1 : 0;
    }

    initDaxReporting () {

        this.userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
        comscore.daxPageMeasurements(this.initialPageLabels, {
            userLocation: this.userLocation,
            userID: this.userID,
            isLoggedIn: this.isLoggedIn,
            currentSite: STV.comscoreTags.siteName,
            screenWidthHeight,
            adBlockerEnabled: this.adBlockerEnabled,
            referrer
        });

        this.reportSocialShares();
        this.reportScrolling();
        this.reportClicks();
        if (this.weatherComponent) {
            this.reportWeatherLocationSearches();
        }
        this.reportRegistrationAbandoned();
    }


    reportClicks () {
        const clickTracker = delegate('[stv-click]', (event, el) => {
            try {
                const labels = JSON.parse(el.getAttribute('stv-click'));
                // event.preventDefault();
                const href = el.href;

                comscore.daxClicked(this.initialPageLabels, {
                    eventType : labels.eventType,
                    eventAction : labels.eventAction || 'clickin',
                    eventMethod : 'click',
                    placement: labels.placement,
                    destination: labels.destination,
                    userLocation: this.userLocation,
                    userID: this.userID,
                    isLoggedIn: this.isLoggedIn
                });

                // if (href) {
                //     document.location.href = href;
                // }
            } catch (error) {
                console.warn('STV Dax label error - ' + error);
            }
        });

        document.body.addEventListener('click', clickTracker);
    }



    // e.g. <button stv-social-share='{"placement" : "article-top", "destination" : "facebook"}'>Facebook share</button>
    reportSocialShares() {
        let userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
        let socialShareBtns = [].slice.call(document.querySelectorAll('[stv-social-share]'));
        if (socialShareBtns.length) {
            let eventHandler = labels => {
                let shareLabels = {
                    eventType : 'social',
                    eventAction : 'share',
                    eventMethod : 'click',
                    placement: labels.placement,
                    destination: labels.destination,
                    userLocation: this.userLocation,
                    userID: this.userID,
                    isLoggedIn: this.isLoggedIn
                };

                comscore.daxSocialShare(this.initialPageLabels, shareLabels);
            }

            socialShareBtns.forEach(socialShareBtn => {
                let labels;
                try {
                    labels = JSON.parse(socialShareBtn.getAttribute('stv-social-share'));
                    socialShareBtn.addEventListener('click', () => {
                        eventHandler(labels);
                    });
                } catch (error) {
                    console.warn('STV Dax label error - ' + error);
                }
            });
        }
    }



    reportRegistrationAbandoned() {
        let hasRegistered = false;
        const getFilledInFields = () => {
            const fields = {
                'firstName': 'fn',
                'lastName': 'ln',
                'email': 'ed',
                'password': 'p',
                'dob': 'db',
                'address': 'lo'
            };
            return this.joinFormComponent.validatedFields.map(inputComponent => {
                const inputValue = document.getElementById(inputComponent.id).value;
                if (!!inputValue) {
                    return inputComponent.id;
                }
            })
            .filter(element => {
                return !!element;
            })
            .map(element => {
                return fields[element];
            }).join('|');
        };

        const callComscore = () => {
            const userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
            const filledInFields = getFilledInFields();
            comscore.daxRegistrationAbandoned(this.initialPageLabels, {
                filledInFields,
                userLocation,
                userID: this.userID,
                isLoggedIn: this.isLoggedIn
            });
        };
        /**
         * To report the filled in reg fields on registration abandonment we need know the user's journey. The user opens
         * the Sign-in/Join modal and is presented with an email address input. They enter their email address which is their login ID,
         * if that login ID doesn't exist we open the join modal which contains the join form. The user fills out a few fields in the join
         * form and decides they don't want to join STV for whatever reason. We want to report which fields they have filled in so
         * we can recognise any join blocking patterns e.g. users don't like filling in the Location field.
         */

        /**
         * ## If a user has the join modal open and they then close the tab/browser we want to report which fields they have filled in.
         *
         * Set up a join modal open event listener. In that event set up another listener for a tab/browser close event and register
         * a handler function that will report the filled in join form fields.
         */
        this.joinModalComponent.on('modal-open-before', () => window.addEventListener('unload', callComscore));

        /**
         * ## If the user closes the join modal (a.k.a abandoning joining) we want to report which fields they have filled in. Bear in mind
         * that the join modal also closes if the user actually joins and we don't want to report the filled in fields if the user joined, obviously
         * because they didn't abandon joining.
         *
         * Set up a join modal close event listener. In the event handler check that the modal didn't close as a result of the user joining by checking
         * parent scope hasRegistered variable. if it didn't then report the filled in fields. If the join modal closed after the user joined then reset
         * the hasRegistered variable to false. Also, now that the modal has closed we don't need to listen to a tab/browser close event so just unbind it.
         */
        this.joinModalComponent.on('modal-close-before', () => {
            if (hasRegistered === false) {
                callComscore();
            } else {
                hasRegistered = false;
            }
            window.removeEventListener('unload', callComscore);
        });

        /**
         * ## If a user joins, the join modal will close a couple of seconds later. Make sure that the join-modal-close listener knows that it closing
         * as a result of the user joining and not abandoning joining.
         *
         * Set up a join form submit success listener and in the handler set the parent scope hasRegistered variable to true.
         */
        this.joinFormComponent.on('submission', () => hasRegistered = true);
    }


    getUsersSelectedRegions () {
        let locationsKey = {
            3121 : 'gw',
            3120 : 'ee',
            3134 : 'an',
            3119 : 'dt',
            3127 : 'c',
            3122 : 'hi'
        };

        if (localStorage) {
            try {
                let setLocations = JSON.parse(localStorage.getItem('metro-region-sections')) || ['gw'];

                let locationLabel = setLocations.reduce((previousVal, location) => {
                    previousVal.push(locationsKey[location]);
                    return previousVal;
                }, []).join('|');

                return locationLabel;
            } catch (error) {
                console.warn('STV Dax label error - ' + error);
                return '';
            }
        } else {
            return 'gw';
        }
    }


    reportScrolling () {
        let userLocation;
        let viewportHeight = document.body.clientHeight;
        let placement = '';
        let scrolled = false;
        let scrolledBottom = false;
        let callComscore = () => {
            comscore.daxScrolled(this.initialPageLabels, {
                eventType : 'nav',
                eventAction : 'scroll',
                eventMethod : 'scroll',
                placement: placement,
                userLocation: this.userLocation,
                userID: this.userID,
                isLoggedIn: this.isLoggedIn
            });
        }
        let getScrollPosition = () => {
            if (!scrolled) {
                userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
                placement = 'top-page';
                callComscore();
                scrolled = true;
            } else if (!scrolledBottom && window.innerHeight + document.body.scrollTop >= document.body.offsetHeight - 300) {
                userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
                placement = 'bottom-page';
                callComscore();
                scrolledBottom = true;
            }

        };
        window.addEventListener('scroll', debounce(getScrollPosition, 1000));
    }


    reportWeatherLocationSearches () {
        let userLocation = this.getUsersSelectedRegions(); // report the user's most up to date selected regional sections
        this.weatherComponent.on('weather-location-change', weatherLocation => {
            comscore.daxWeatherLocation(this.initialPageLabels, {
                location: weatherLocation,
                userLocation: this.userLocation,
                userID: this.userID,
                isLoggedIn: this.isLoggedIn
            });
        });
    }

    isUsingAdBlocker() {
        let testAd = document.createElement('div');

        testAd.id = 'adsense';
        testAd.style.width = '1px';
        testAd.style.height = '1px';
        testAd.style.backgroundColor = 'transparent';
        testAd.style.color = 'transparent';
        testAd.style.overflow = 'hidden';
        testAd.style.display = 'none';
        testAd.innerHTML = 'Test advertisement';
        document.body.appendChild(testAd);

        const adBlockerEnabled = window.getComputedStyle(
            document.getElementById('adsense'), null
        ).getPropertyValue('visibility');

        return adBlockerEnabled === 'hidden';
    }


}
