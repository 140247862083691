import { Singleton, log } from '../stv';

const OPTS = 'data-options';

@Singleton
export default class ComponentTree {
    components  = {};
    tree        = {};
    list        = {};
    root        = document.body;
    idIterator  = 0;

    constructor() {
        document.addEventListener('DOMContentLoaded', this.startComponents.bind(this));
    }

    _buildComponentTree() {
        this._scanDocument(this.root, this.tree);
    }

    _executeNode( node ) {
        if (node.children) node.children.forEach(child => this._executeNode(child));
        if (node.matchedNode && node.matchedComponent) {

            let elements    = { root: node.matchedNode };
            let Component   = this.components[node.matchedComponent];
            let annotation  = Component.annotation;
            let options     = undefined;

            if ( elements.root.hasAttribute(OPTS) ) {
                try {
                    options = JSON.parse( elements.root.getAttribute(OPTS) );
                } catch (e) {
                    console.log("error: options JSON invalid, proceeding");
                }
            }

            if ( annotation.elements ) {
                Object.keys( annotation.elements ).forEach((key) => {
                    let nodeList = elements.root.querySelectorAll( annotation.elements[key] );
                    elements[key] =  Array.from( nodeList );
                });
            }

            let children = new ChildTree(node.children || []);
            node.instance = new Component();
            node.matchedNode.id = node.matchedNode.id || this._getNextId();
            this.list[node.matchedNode.id] = node;

            // Setup instance properties
            node.instance.id        = node.matchedNode.id;
            node.instance.elements  = elements;
            node.instance.children  = children;
            node.instance.options   = node.instance.options || {};

            Object.assign(node.instance.options, options);
        }
    }

    _mountComponent(node) {
        if (node.children) node.children.forEach(child => this._mountComponent(child));
        if (node.instance && node.instance.componentHasMounted && typeof(node.instance.componentHasMounted) === 'function') node.instance.componentHasMounted();
    }

    _getNextId() {
        return `stv-component-${this.idIterator++}`;
    }

    _scanDocument(node, branch) {
        if (!node.children) return;

        let children        = Array.from(node.children);
        let components      = Object.keys(this.components);

        children.forEach(child => {
            let matchedNode         = undefined;
            let matchedComponent    = undefined;

            components.forEach(item => {
                let name = this.components[item].annotation.name;
                if (child.hasAttribute(name)) {
                    matchedNode = child;
                    matchedComponent = item;
                }
            });

            if (matchedNode) {
                let newBranch = { matchedNode, matchedComponent, instance: undefined };
                if (!branch.children) branch.children = [];
                branch.children.push(newBranch)
                this._scanDocument(child, newBranch);
            } else {
                this._scanDocument(child, branch);
            }
        });
    }

    register(component) {
        this.components[component.annotation.name] = component;
    }

    startComponents() {
        this._buildComponentTree();
        this._executeNode(this.tree);
        this._mountComponent(this.tree);
    }
}

class ChildTree {
    _tree;
    _list;

    constructor(tree) {
        this._tree = tree;
        this._list = this._buildList(this._tree);
    }

    _buildList(children) {
        let build = [];
        children.forEach(child => {
            build.push(child);
            if (child.children) {
                build = build.concat(this._buildList(child.children));
            }
        });
        return build;
    }

    find(componentID) {
        return this._list.filter(item => {
            return (item.matchedComponent === componentID) ? item : false;
        });
    }

    get tree() {
        return this._tree;
    }

    get list() {
        return this._list;
    }
}
