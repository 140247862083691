'use strict';

import { Singleton } from '../stv';

@Singleton
export default class ServiceManager {
    constructor() {
        this.services = {};
        this.index = 0;
    }

    registerService( serviceClass ) {
        let id = `service:${this.index}`;
        this.services[id] = serviceClass;
        serviceClass.service = { id };
        this.index ++;
    }

    startService( id ) {
        return this.services[id].getInstance();
    }
}
