'use strict';

/**
 * ## SiteNav
 * Sets up the toggles for the mobile view of sitenav
 */

import { Component } from '../common/stv';

const mobileMenuOpenClass = 'news-mobile-nav-is-open';
const subMenuOpenClass = 'is-open';

/**
 * @class SiteNav
 */
@Component({
    name: 'stv-news-nav',
    elements: {
        mobileNavToggle: '[stv-news-mobile-nav-toggle]',
        newsSubNavToggle: '[stv-news-sub-nav-toggle]',
        newsSubNav: '[stv-news-sub-nav]'
    }
})
export default class SiteNav {
    componentHasMounted() {
        this.bindEvents();
    }

    /**
     * binds action to menu toggles
     * @method bindEvents
     */
    bindEvents() {
        let currentScrollPosition;
        const newsSubNav = this.elements.newsSubNav[0];
        const newsSubNavToggle = this.elements.newsSubNavToggle[0];

        // Mobile menu bind
        this.elements.mobileNavToggle[0].addEventListener('click', (e) => {
            e.preventDefault();
            const mobileNavIsOpen = this.elements.root.classList.toggle(mobileMenuOpenClass);

            /* prevent body scrolling
             * This is needed for iPhone 4
             */
            if (mobileNavIsOpen) {
                currentScrollPosition = window.scrollY || window.pageYOffset;
                document.body.classList.add('no-scroll');
            } else {
                document.body.classList.remove('no-scroll');
                window.scrollTo(0, currentScrollPosition);
            }
        });

        // Submenu bind
        // if statements currently needed as elements dont and may not exist on all environments
        if (newsSubNavToggle) {
            newsSubNavToggle.addEventListener('click', (e) => {
                if (newsSubNav) {
                    e.preventDefault();
                    newsSubNav.classList.toggle(subMenuOpenClass); 
                }
            });
        }
    }
}
