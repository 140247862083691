import { parseQueryString, getComponent } from '../common/stv';

export default function SharedItem (url) {
	const parameters   = parseQueryString(url);
	const blockgroupid = parameters.blockgroupid;
	const postinsert   = getComponent('shared-items');

	// if we have an article to show
	if (!isNaN(blockgroupid) && postinsert) {
		postinsert.open('shared-post');
		postinsert.on('modal-close', target => postinsert.close());
	}
}
