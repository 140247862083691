import { Implements, Component, Broadcaster, getComponent, ukPostcodeRegEx, select } from '../common/stv';
import errorCodes from '../common/errorcodes';
import moment from '../../../libs/moment.min';

const CLASS_VALID   = 'is-valid';
const CLASS_INVALID = 'is-not-valid';
const PATTERNS = {
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ukpostcode: ukPostcodeRegEx,
    ukphonenumber: /^\+?(?:\d\s?){10,20}$/
}

@Component({
    name: 'validated-field'
})
@Implements(Broadcaster)
export default class ValidatedField {
    valid = undefined;
    required;
    pattern;
    ageRestriction;
    match;
    formatDate;
    formLevelError;
    erroredFormValue;
    enforcedValidation = false;
    enforcedValidationState;

    constructor() {
        this.options = {
            testOn: 'blur,change',
            addClass: true,
            minAge: 16,
            maxAge: 115,
        }
    }

    componentHasMounted() {
        let root = this.elements.root;

        this.required   = root.hasAttribute('data-required');
        this.formatDate = root.hasAttribute('data-format-date');
        this.pattern    = (root.getAttribute('data-pattern')) || undefined;
        this.match      = (root.getAttribute('data-match')) || undefined;
        this.agerestriction = parseInt(root.dataset.agerestriction, 10);

        this.bindEvents();
    }

    forceValidation(state) {
        this.enforcedValidation = true;
        this.enforcedValidationState = state;
    }

    doNotForceValidation() {
        this.enforcedValidation = false;
    }

    reset() {
        let root = this.elements.root;
        root.value                  = '';
        this.erroredFormValue       = '';
        this.formLevelError         = undefined;
        this.valid                  = undefined;

        root.classList.remove(CLASS_VALID);
        root.classList.remove(CLASS_INVALID);
    }

    bindEvents() {
        let field = this.elements.root;
        let events = this.options.testOn.split(',');

        events.forEach(evt => {
            field.addEventListener(evt, this.validate.bind(this));
        });
    }

    setFormLevelError(errorCode) {
        this.formLevelError = errorCode;
        this.erroredFormValue = this.elements.root.value;
    }

    validate() {
        let field = this.elements.root;
        let validationState = {};

        // Innocent until proven guilty
        this.valid = true;

        if (this.pattern) {
            const re = new RegExp(PATTERNS[this.pattern] ? PATTERNS[this.pattern] : this.pattern);

            const email = () => re.test(field.value = field.value.toString().trim());
            const ukpostcode = () => re.test(field.value.replace(/\s+/g, '').toUpperCase());
            const otherwise = () => re.test(field.value);

            this.valid = select({
                test: this.pattern,
                routes: { email, ukpostcode, otherwise }
            });

            if (!this.valid) validationState.errorCode = errorCodes.fieldValidationFailed;
        }

        if (this.match) {
            let matchedField = document.querySelector(this.match);
            if (matchedField) {
                this.valid = (matchedField.value === field.value && matchedField.value.length);
                if (!this.valid) validationState.errorCode = errorCodes.fieldsDoNotMatch;
            } else {
                throw "Matching field not found on validated-field: " + this.elements.root;
            }
        }

        if (this.formatDate) {
            let formattedDate = this.autoFormatDate(this.elements.root.value);
            if (formattedDate) this.elements.root.value = formattedDate;
            if (!this.valid) validationState.errorCode = errorCodes.fieldValidationFailed;
        }

        if (this.formLevelError) {
            this.valid = false;
            validationState.errorCode = this.formLevelError;

            // The bad value has changed, clear the error.
            if (field.value !== this.erroredFormValue) {
                this.formLevelError = undefined;
            }
        }

        if (this.enforcedValidation) {
            this.valid = this.enforcedValidationState;
            validationState.errorCode = errorCodes.fieldForcedValidationError;
        }

        if (this.required && ((field.type === 'checkbox' && !field.checked) || (field.type !== 'checkbox' && !field.value))) {
            this.valid = false;
            validationState.errorCode = errorCodes.fieldIsRequired;
        }

        validationState.valid = this.valid;
        this.updateDOM();
        this.broadcast('validation', validationState);
    }

    autoFormatDate(inputDate) {
        let input = "" + inputDate;
        let validSplitCharacters = ['.', '/', '-', ' '];
        let dateSegments = [];
        let assumptions = { 4: 'DMYY', 5: 'DDMYY', 6: 'DDMMYY', 7: 'DDMYYYY', 8: 'DDMMYYYY' }

        // First step is to figure out which part of the string represents day month of year.
        validSplitCharacters.forEach(splitter => {
            let splitString = input.split(splitter);
            if (splitString.length > 1) dateSegments = splitString;
        });

        // Incorrect number of splitters, can't be a date. Bail out.
        if (dateSegments.length !== 3 && dateSegments.length !== 0) {
            this.valid = false;
            return;
        }

        // No valid split character was found, let's make some assumptions.
        if (!dateSegments.length) {
            // There's no way this could be a valid date, bail and fail.
            if (input.length < 4 || input.length > 8) {
                this.valid = false;
                return;
            }
            let assumedPattern = Array.from(assumptions[input.length]);
            let dateIndexLookup = { 'D' : 0, 'M' : 1, 'Y' : 2 };
            let assumedDay;

            dateSegments = ['', '', ''];

            assumedPattern.forEach((item,index) => {
                let segmentIndex = dateIndexLookup[item];
                dateSegments[segmentIndex] += input[index];
            });
        }

        // Format the segments in to DD/MM/YYYY
        let [day, month, year] = dateSegments.map(segment => (segment.length === 1) ? "0" + segment : "" + segment );

        if (year.length === 2) {
            const minAcceptedAge = 1;
            const currentYear = (new Date()).getFullYear();
            const globalAverageAge = 28.4;
            const currentCentury = Math.floor(currentYear/100);
            const minViableCentury = currentCentury - 1;

            let minPossibleAge = currentYear - parseInt("" + currentCentury + year);
            let maxPossibleAge = currentYear - parseInt("" + minViableCentury + year);
            let minPossibleAgeDifference = Math.abs(globalAverageAge - minPossibleAge);
            let maxPossibleAgeDifference = Math.abs(globalAverageAge - maxPossibleAge);
            let mostLikelyCentury = (minPossibleAgeDifference < maxPossibleAgeDifference && minPossibleAge > minAcceptedAge) ? currentCentury : minViableCentury;

            year = "" + mostLikelyCentury + year;
        }

        let finalDate = `${day}/${month}/${year}`;
        let finalDateMoment = moment(finalDate, 'DD/MM/YYYY', true);

        this.valid = finalDateMoment.isValid()
            && (moment().diff(finalDateMoment, 'years') >= this.options.minAge)
            && (moment().diff(finalDateMoment, 'years') <= this.options.maxAge);

        return `${day}/${month}/${year}`;
    }

    updateDOM() {
        if (this.options.addClass) {
            this.elements.root.classList
                .add( this.valid ? CLASS_VALID : CLASS_INVALID );
            this.elements.root.classList
                .remove( !this.valid ? CLASS_VALID : CLASS_INVALID );
        }
    }
}
