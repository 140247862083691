import LoginService from '@stvgroup/login-service';
import { getDomain, getEnvironment } from '../helpers';

const currentDomain = getDomain();
const environment = getEnvironment();


const loginService = LoginService({
  currentDomain,
  environment,
  isSecureProtocol: location.protocol === 'https:',
});

export default loginService;
