
import { Singleton, Implements, Broadcaster } from  '../common/stv';
import { setCookie, deleteCookie } from '../common/cookies';

const GIGYA_RESPONSE_FAIL       = 'FAIL';
const GIGYA_REGTOKEN_TIMEOUT    = (1 * 60 * 60 * 1000);
const GIGYA_SUCCESS             = 0;
const GIGYA_EXTRA_FIELDS        = ['address', 'phones'];

@Singleton
@Implements( Broadcaster )
export default class Account {

    /* Private */
    _userObject        = undefined;
    _regToken          = undefined;

    /* Private */
    validateGigyaResponse = (response) => {
        if (response.errorCode !== GIGYA_SUCCESS) {
            throw response;
        }
        return response;
    };

    _setLoginState = (response) => {
        if (response.status !== GIGYA_RESPONSE_FAIL) {
            if (response.sessionInfo && response.sessionInfo.cookieName && response.sessionInfo.cookieValue) {
                setCookie(response.sessionInfo.cookieName, response.sessionInfo.cookieValue);
            }
            setCookie('STV_USERID', response.UID);
            setCookie('STV_POSTCODE', response.data ? response.data.postcode : '');
            this.broadcast('log-in', response);
            this.broadcast('state-changed', response);
        }

        return response;
    };

    _attemptRegistration(optionsObject, profileObject, dataObject) {
        this.broadcast('attempt-registration');
        optionsObject.regToken  = this._regToken;
        optionsObject.finalizeRegistration = true;
        optionsObject.profile   = JSON.stringify(profileObject);
        optionsObject.data      = JSON.stringify(dataObject);

        return new Promise((resolve) => {
            optionsObject.callback  = (response) => resolve(response);
            this._accounts.register(optionsObject);
        })
        .then(this.validateGigyaResponse)
        .then(response => {
            this.broadcast('registration', response);
            this._setLoginState(response);
        });
    }

    /* Public */
    register = (optionsObject, profileObject, dataObject) => {
        return this._getRegistrationToken()
            .then(this._attemptRegistration.bind(this, optionsObject, profileObject, dataObject));
    };

    login = (loginID, password) => {
        this.broadcast('attempt-login');
        return new Promise((resolve) => {
            this._accounts.login({
                loginID,
                password,
                callback: response => resolve(response),
            });
        })
        .then(this.validateGigyaResponse)
        .then(this._setLoginState);
    };

    logout = () => {
        return new Promise((resolve) => {
            this._accounts.logout({
                callback: response => {
                    this._userObject = undefined;
                    this.broadcast('log-out');
                    this.broadcast('state-changed');
                    resolve(response);
                    deleteCookie('STV_USERID');
                    deleteCookie('STV_POSTCODE');
                    deleteCookie('STV_GROUPTOKEN');
                },
            });
        });
    };

    updateUserObject = (userObject = {}) => {
        const self = this;
        return new Promise((resolve, reject) => {
            userObject.callback = function callback(response) {
                if (response.errorCode === GIGYA_SUCCESS) {
                    resolve(response);
                    self.broadcast('state-changed', response);
                } else {
                    reject(response);
                }
            };
            this._accounts.setAccountInfo(userObject);
        })
        .then(this.validateGigyaResponse)
        .then(this.flushUserObject)
        .then(uo => this.broadcast('state-changed', uo));
    };

    getUserObject = () => {
        return new Promise((resolve) => {
            if (!this._userObject || !this._userObject.profile.address) {
                this._accounts.getAccountInfo({
                    callback: response => {
                        resolve(response);
                    },
                    extraProfileFields: GIGYA_EXTRA_FIELDS.join(','),
                });
            } else {
                resolve(this._userObject);
            }
        })
        .then(this.validateGigyaResponse)
        .then(response => this._userObject = response);
    };

    isLoggedIn = () => {
        return this._userObject ? true : false;
    };


    resendVerificationCode = () => {
        return new Promise((resolve, reject) => {
            const options = {};
            options.regToken = this._regToken;
            options.callback = function callback(response) {
                if (response.errorCode === GIGYA_SUCCESS) {
                    resolve(response);
                } else {
                    reject(response);
                }
            };
            this._accounts.resendVerificationCode(options);
        });
    };

    flushUserObject = () => {
        this._userObject = undefined;
        return this.getUserObject();
    };

    loginIDAvailable(loginID) {
        return new Promise((resolve) => {
            this._accounts.isAvailableLoginID({
                loginID,
                callback: response => resolve(response)
            });
        });
    };

    accountsSearch = ({ query, querySig, expTime }) => {
        return new Promise(resolve => {
            this._accounts.search({
                query,
                querySig,
                expTime,
                callback: resolve
            });
        });
    }

}

window.STV = window.STV || {};
window.STV.account = Account.getInstance();
