// Imports
import ServiceManager   from './stv-core/servicemanager';
import Broadcaster      from './stv-core/broadcaster';
import ComponentTree    from './stv-core/componenttree';

ComponentTree.getInstance();

// Global events object
const signalBox = {};
Object.assign( signalBox, Broadcaster );
signalBox._alloc();

export { Broadcaster, signalBox };

// Externals
export const ukPostcodeRegEx = /(GIR 0AA)|^((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY]))))[0-9][ABDEFGHJLNPQRSTUWXYZ]{2})$/;


// functional replacement for the switch statement
export function select({ test, routes }) {
  return routes[test]
    ? runRoute(routes[test])
    : runRoute(routes.otherwise);

  function runRoute(route) {
    return (typeof(route) === 'function')
      ? route()
      : (typeof(route) === 'object')
        ? Object.keys(route)
          .reduce((acc, next) => ({ ...acc, [next]: runRoute(route[next]) }), {})
        : undefined;
  }
}

// Returns a function that re-enables the button, passing true as an argument will
// also remove the disable on click functionality for next click.

// taken from ITCSS/1-settings/_breakpoints.scss
export const cssBreakPoints = {
    micro: 390,
    palm: 480,
    alpha: 560,
    beta: 680,
    gamma: 768,
    delta: 840,
    epsilon: 980,
    lap: 1024,
    zeta: 1200
};

export function disableOnClick(element) {
    const eventType = 'click';
    const attrType = 'disabled';
    const listener = evt => requestAnimationFrame(() => element.setAttribute(attrType, true));

    element.addEventListener(eventType, listener);
    return removeForever => {
        element.removeAttribute(attrType);
        if (removeForever) element.removeEventListener(eventType, listener);
    };
}

export function buildFetchPostObject( data ) {
    return {
        method: 'post',
        headers: { 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        body: JSON.stringify(data),
    };
}

export function log() {
    if (window.console) {
        console.log( Array.prototype.slice.call(arguments));
    }
}

export function throttle(fn, delay, context = this) {
    let timeout;
    return () => {
        timeout = timeout || setTimeout(() => {
            fn.apply(context, arguments);
            timeout = undefined;
        }, delay);
    };
}

export function inAppBrowser() {
    const result = {
        detected: false,
        facebook: false,
        twitter: false,
        ios: false,
        android: false
    };
    const ua = window.navigator.userAgent;

    // Facebook in-app browser on iOS
    if (ua.indexOf('FBIOS') > -1) {
        result.facebook = true;
        result.ios      = true;
    }

    // Facebook in-app browser on Android
    if (ua.indexOf('FB_IAB') > -1 || ua.indexOf('FB4A') > -1) {
        result.facebook = true;
        result.android  = true;
    }

    // Twitter in-app browser on iOS
    if (ua.indexOf('Twitter for') > -1) {
        result.twitter = true;
        result.ios     = true;
        // we assume iOS because user agent string is not customised in Twitter Android app
    }

    // A shortcut test for either in-app browser
    if (result.facebook || result.twitter) { result.detected = true; }

    return result;
}

/**
 * # Taken from underscore.js
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
export function debounce(func, wait, immediate) {
    let timeout;
    let args;
    let context;
    let timestamp;
    let result;

    const later = () => {
        const last = new Date().getTime() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return () => {
        context = this;
        args = arguments;
        timestamp = new Date().getTime();
        const callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}


export function getComponent(id) {
    const componentTree = ComponentTree.getInstance();
    return (componentTree.list[id] && componentTree.list[id].instance) ? componentTree.list[id].instance : undefined;
}

export function getComponentsByType(type) {
    const componentTree = ComponentTree.getInstance();
    return Object.keys(componentTree.list)
        .map(key => componentTree.list[key])
        .filter(item => item.matchedComponent === type)
        .map(item => item.instance);
}

const autoPostfixStyles = ['width', 'height', 'left', 'top'];
const ignoreWhen = ['px', 'rem', '%', 'auto', 'inherit', 'em'];

export function setStyle(el, styles) {
    Object.keys(styles).forEach(key => {
        let style = styles[key] + '';
        if (autoPostfixStyles.includes(key)) {
            const styleContainsUnit = ignoreWhen.filter(item => style.indexOf(item) > 0).length;
            if (style.length && !styleContainsUnit) style += 'px';
        }
        el.style[key] = style;
    });
}

export function delegate(selector, callback) {
    return function(e) {
        const elements = Array.from(document.querySelectorAll(selector));
        elements.forEach(el => {
            if (el.isEqualNode(e.target) || el.contains(e.target)) {
                callback(e, el);
            }
        });
    };
}

// Internals
function startRegisteredService( serviceClass ) {
    if ( serviceClass.service.id ) {
        const manager = ServiceManager.getInstance();
        manager.startService( serviceClass.service.id );
    }
}

// Decorator Functions
export function Singleton( target ) {
    target.getInstance = function getInstance(data) {
        return ( target.instance = target.instance || new target(data) );
    };
}

export function Component( annotation ) {
    return ( target ) => {
        const componentTree = ComponentTree.getInstance();
        target.annotation = annotation;
        componentTree.register( target );
    };
}

export function Service(target) {
    const manager = ServiceManager.getInstance();
    Singleton(target);
    manager.registerService(target);
    document.addEventListener('DOMContentLoaded', startRegisteredService.bind(undefined, target));
}

export function Implements( ...interfaces ) {
    return ( target ) => {
        interfaces.forEach((item) => {
            Object.assign( target.prototype, item );
        });
    };
}

// returns an array of elements, instead of a nodelist
export function arraySelector(selector) {
    return Array.from(document.querySelectorAll(selector));
}

export function parseQueryString(url) {
    return !url.includes('?') ? {} : url
    .split('?')[1]
    .split('&')
    .reduce((list, query) => {
        const [ key, value ] = query.split('=');
        return { ...list, [key]: value || '' };
    }, {});
}

export function paramSerialiser(params) {
    let pairs = [];
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            pairs.push( key + '=' + params[key] );
        }
    }
    return pairs.join('&');
}

export function getFirstValueFromObject(obj) {
    return obj[Object.keys(obj)[0]];
}


export function getProperty(obj, path) {
    return obj ? path.split('.').reduce((result = false, current) => {
        return result
            ? result[current]
            : result;
    }, obj) : undefined;
}

export function scrollTo(elements, to, duration = 750) {
    // taken from https://gist.github.com/andjosh/6764939
    [].concat(elements).forEach(element => {
        const start = element.scrollTop;
        const change = to - start;
        const increment = 20;
        let currentTime = 0;

        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        function easeInOutQuad(t, b, c, d) {
          t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        }

        function animateScroll() {
            currentTime += increment;
            const val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        }
        animateScroll();
    });
}

export function getRectTopYPosition(element) {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    return elemRect.top - bodyRect.top;
}

export function getStickyNavHeight() {
    return document.querySelector('[sticky-nav]').offsetHeight;
}

export function scrollToElementMinusStickyNavHeight(element, duration = 750) {
    const stickyNavHeight = getStickyNavHeight();
    scrollTo(
        [document.body, document.documentElement],
        getRectTopYPosition(element) - stickyNavHeight,
        duration
    );
}

export function fetchHeaders() {
    // need to include headers with fetch API to satisfy backend check on api request
    const headers = new Headers();
    headers.append('X-Requested-With', 'XMLHttpRequest');
    return { headers };
}
