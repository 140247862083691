import {
    validateCompsTermsCheckBox,
    handleCompetitionEntryError,
    postCompetitionEntry
} from './units';
import {
    getComponent,
    cssBreakPoints,
    scrollToElementMinusStickyNavHeight,
    fetchHeaders,
    Broadcaster
} from '../../common/stv';
import {
    UAParser
} from '../../../../libs/ua-parser';
import animate from '../../../../libs/velocity.min';
import moment from '../../../../libs/moment.min';
import {
    enteredCompetitionsFetch
} from '../../modules/competitions';
import loginService from '../../account/initLoginService';
import {
    differenceInYears
} from 'date-fns';
import {
    getProfileOrigin
} from '../../helpers';
const profileOrigin = getProfileOrigin();

function run() {
    const $competitionContainer = document.querySelector('#competition__container');
    if (!$competitionContainer) return;

    const _UAParser = UAParser.UAParser();

    /**
     * get dom nodes, data and components in the component scope
     */
    const {
        competitionid,
        agerestriction,
    } = $competitionContainer.dataset;

    const $competitionContent = Array.from($competitionContainer.querySelectorAll('.competition__details'));
    const $competitionFormsContainer = $competitionContainer.querySelector('#competition__forms');
    const $competitionFormsInner = document.querySelector('.competition__content--forms');
    const $spinner = $competitionContainer.querySelector('#competition__spinner-container');
    const carousels = document.querySelectorAll('.carousel-background');
    const competitionModalGroup = getComponent('competition-entry-modal-group');
    const competitions = document.querySelector('.js-competition-list');
    const enterButton = document.querySelector('.competition__entry-cta');
    const enteredMessage = document.querySelector('.competition__cta-box--entered-date');
    const competitionEnteredDate = $competitionContainer.querySelector('.competition__entered-date');
    const initialEnteredMessage = competitionEnteredDate.innerHTML;

    const $emailSection = $competitionContainer.querySelector('#competition__email-section');

    // sign in form
    const $signInSection = $competitionContainer.querySelector('#competition__sign-in-section');

    // join form
    const $joinSection = $competitionContainer.querySelector('#competition__join-section');

    // reset password form
    const $resetPasswordSection = $competitionContainer.querySelector('.stv-modal__reset-password-section');

    // additional info form
    const additionalInfoFormComponent = getComponent('additional-info-form--competition');

    const $additionalInfoSection = $competitionContainer.querySelector('#competition__additional-info-section');
    const $additionalInfoForm = additionalInfoFormComponent.elements.root;
    const $additionalInfoFormTermsCheckBox = $additionalInfoForm.querySelector('#additional-info-form__terms--competition');
    const $additionalInfoFormNumber = $additionalInfoForm.querySelector('#additional-info-form__number--competition');

    const $competitionEntryLink = $competitionContainer.querySelector('#competition__entry-btn');
    const $competitionLogin = document.querySelector('#competition_login-user');
    const $competitionEndMessageBeforeEntry = $competitionContainer.querySelector('#competition__end-before-entry');
    const $competitionEndMessageAfterEntry = $competitionContainer.querySelector('#competition__end-after-entry');
    const $competitionEntryErrorMessages = $competitionContainer.querySelector('#competition__entry-error-messages');
    const $competitionEntryErrorMessagesUnder18 = $competitionContainer.querySelector('#competition__entry-error-under-18');
    const $competitionEntryErrorLink = $competitionContainer.querySelector('#competition__entry-error-link');
    const $competitionTermsStatement = $competitionContainer.querySelector('#competition__terms-statement');
    const $specificTermsLinks = Array.from($competitionContainer.querySelectorAll('[competition-terms-link]'));
    const $successMessages = Array.from($competitionContainer.querySelectorAll('.competition__success'));

    // questions
    const questions = document.querySelector('.competition__questions');

    // on dom loaded, get the user object and show the appropriate UI state
    getEnteredState();

    // on logout, change the UI state
    Broadcaster
        .on('log-out', resetCompetitionPage);

    // on additional info form submission, update user profile data then enter competition
    additionalInfoFormComponent
        .on('submission', formData => {
            const {
                referral,
                tmarketing,
                phone
            } = formData;
            if (validateCompsTermsCheckBox($additionalInfoFormTermsCheckBox)) {
                let phoneNumber = phone;
                const phoneNumberFirstNumber = phone.slice(0, 1);
                if (phoneNumberFirstNumber !==  '0'  && phoneNumberFirstNumber !==  '+') {
                    phoneNumber = 0 + phone;
                  }
                phoneNumber = phoneNumber.replace(/[^+0-9]/g, '').replace(/^0/, '+44');
                const newUserData = {
                    phone_number: phoneNumber,
                    ...(tmarketing && {
                        'custom:targetedAdvertising': tmarketing.toString(),
                    })
                };

                $additionalInfoSection.classList.add('muted');
                loginService
                    .updateUser(newUserData)
                    .then(() => handleCompetitionEntrySubmission({
                        tmarketing,
                        referral
                    }))
                    .then(() => $additionalInfoSection.classList.remove('muted'))
                    .catch(() => {
                        $additionalInfoSection.classList.remove('muted');
                        show([$competitionEntryErrorMessages]);
                        showEntryErrorMessage('#competition__entry-error-message--general');
                    });
            }
        });

    // on continue button click, login using the MySTV Portal
    $competitionLogin.addEventListener('click', loginMyStvPortal);

    $competitionEntryErrorLink
        .addEventListener('click', event => handleCompetitionEntryError({
            competitionTitle: $competitionContainer.querySelector('#competition__title').innerText,
            event,
            _UAParser
        }));


    //  competition entry button does not exist on offline competitions
    if ($competitionEntryLink) {
        $competitionEntryLink
            .addEventListener('click', event => {
                event.preventDefault();
                scrollToElementMinusStickyNavHeight($competitionFormsContainer);
            });
    }


    $specificTermsLinks
        .forEach($specificTermsLink =>
            $specificTermsLink.addEventListener('click', event => {
                event.preventDefault();
                competitionModalGroup.open('competition-terms');
            })
        );


    function getEnteredState() {
        loginService
            .getUser()
            .then((userObject) => {
                if (userObject) {
                    const userDob = userObject.birthdate;
                    const userAge = differenceInYears(new Date(), new Date(userDob));
                    if (userAge >= 18 || agerestriction === undefined) {
                        animate($spinner, 'fadeOut', {
                            duration: 250
                        });
                        const loginID = userObject['cognito:username'];
                        const url = `/ajax/widgets/CompetitionEntry/?competitionId=${encodeURIComponent(competitionid)}&userId=${encodeURIComponent(loginID)}`;

                        // Promise to set the button entered time.
                        // Rejection or failure will lead to the additional info modal.
                        return fetch(url, fetchHeaders())
                            .then(response => response.json())
                            .then(json => {
                                if (!json.timeEntered) return Promise.reject();
                                const entryDate = moment.unix(json.timeEntered).format('dddd[,] Do MMMM');
                                previouslyEntered(entryDate);
                                hide([enterButton]);
                            })
                            .catch(err => {
                                hide([competitions]);
                                show([enterButton, $competitionFormsContainer, questions]);
                                showAdditionalInfoForm(userObject);
                            });
                    }
                    if (userAge < 18 && agerestriction !== undefined) {
                        show([$competitionEntryErrorMessagesUnder18]);
                        animate($spinner, 'fadeOut', {
                            duration: 250
                        });
                    }
                } else {
                    $emailSection.style.opacity = 0;
                    show([$emailSection, enterButton, questions]);
                    animate($spinner, 'fadeOut', {
                            duration: 250
                        })
                        .then(() => {
                            show([$competitionFormsContainer]);
                        })
                        .then(() => animate($emailSection, 'fadeIn', {
                            duration: 250
                        }));
                }
            })
            .catch(err => {
                $emailSection.style.opacity = 0;
                show([$emailSection, enterButton, questions]);
                animate($spinner, 'fadeOut', {
                        duration: 250
                    })
                    .then(() => {
                        show([$competitionFormsContainer])
                    })
                    .then(() => animate($emailSection, 'fadeIn', {
                        duration: 250
                    }));
            });
    }

    function showAdditionalInfoForm(userObject) {
        return showFormSection($additionalInfoSection, () => {
                populateAdditionalInfoFormFields(userObject);
                additionalInfoFormComponent.validate();
            })
            .then(() => scrollIfNeeded($additionalInfoSection));
    }


    function previouslyEntered(entryDate) {
        updateEnteredMessage(entryDate);
        hide([enterButton, $competitionFormsContainer, competitions, questions]);
        show([enteredMessage, $competitionEndMessageBeforeEntry, $competitionTermsStatement]);
    }


    function updateEnteredMessage(entryDate) {
        const newMessage = initialEnteredMessage.concat(entryDate);
        competitionEnteredDate.innerHTML = newMessage;
    }


    function revertEnteredMessage() {
        competitionEnteredDate.innerHTML = initialEnteredMessage;
    }


    function show(params) {
        if (Array.isArray(params)) {
            params.forEach(param => {
                param.classList.remove('hide');
            })
        }
    }


    function hide(params) {
        if (Array.isArray(params)) {
            params.forEach(param => {
                param.classList.add('hide');
            })
        }
    }


    function showEntrySuccessMessage() {
        hide([$competitionFormsContainer, $competitionEndMessageBeforeEntry, $competitionEntryLink, ...$competitionContent, ...carousels]);
        show([$competitionEndMessageAfterEntry, competitions, ...$successMessages]);
    }

    function resetCompetitionPage() {
        if (questions) {
            const questionInputs = Array.from(questions.getElementsByTagName('input'));
            questionInputs.forEach(clearInputElement);
            $competitionEntryErrorMessagesUnder18.classList.add('hide');
        }

        showFormSection($emailSection, () => {
            hide([$additionalInfoSection, $competitionEndMessageAfterEntry, $competitionEntryErrorMessages, competitions, ...$successMessages, enteredMessage]);
            show([enterButton, $competitionFormsContainer, $competitionEndMessageBeforeEntry, $competitionEntryLink, $competitionTermsStatement, ...$competitionContent, questions, ...carousels]);
            revertEnteredMessage();

            [additionalInfoFormComponent].forEach(form => form.reset());
        });
    }


    function showFormSection($formSection, midFade) {
        let height;
        const heightAnimationSpeed = 1.5;
        const allFormSections = [$signInSection, $joinSection, $emailSection, $additionalInfoSection];
        const initialHeight = $competitionFormsInner.offsetHeight;
        $competitionFormsInner.style.height = `${initialHeight}px`;

        // fade all the form sections out
        return animate(allFormSections, 'fadeOut', {
                duration: 250
            })
            .then(() => {
                allFormSections.forEach(section => section.classList.add('hide'));
                show([$formSection]);
                $formSection.style.display = 'block';
                height = $formSection.offsetHeight;
            })

            // mid fade do some stuff like show/hide sections within the section that is about to be shown
            .then(midFade)

            // animate the height. do some calculation for a constant speed for all section heights
            .then(() => animate($competitionFormsInner, {
                height
            }, {
                duration: Math.abs(initialHeight - height) / heightAnimationSpeed,
                easing: 'swing'
            }))

            // fade the form section in
            .then(() => animate($formSection, 'fadeIn', {
                duration: 250
            }))

            // remove all the inline styles that velocity.js leaves behind
            .then(() => [$competitionFormsInner, ...allFormSections].forEach(section => section.removeAttribute('style')))
            .catch();
    }


    // grab value for question or prize draw radio buttons
    function getOption(type) {
        const optionEls = Array.from(document.querySelectorAll(`[name="${type}"]`));
        const checkedEl = optionEls.find(el => el.checked);

        return (optionEls.length > 0) ?
            checkedEl ?
            checkedEl.value :
            -1 :
            null;
    }


    function getAnswer() {
        const answer = document.querySelector('input[name=answer]');
        return answer ?
            (answer === '' || answer.value.length === 0) ?
            -1 :
            answer.value :
            null;
    }


    function clearInputElement(input) {
        if (input.type === 'radio') input.checked = false;
        if (input.type === 'text') input.value = '';
    }


    function handleCompetitionEntrySubmission({
        tmarketing,
        referral,
        hasJoined = false
    }) {
        hide([$competitionEntryErrorMessages]);

        const optionId = getOption('optionId');
        const prizeOptionId = getOption('prizeOptionId');
        const answer = getAnswer();

        // Gets the userobject if the form has adequate selections.
        // Otherwise throws a 1001 error.
        return new Promise((resolve, reject) => {
                const fieldsMissing = optionId === -1 || prizeOptionId === -1 || answer === -1;
                if (fieldsMissing) {
                    reject(1001);
                } else {
                    resolve(loginService.getUser());
                }
            })
            .then(userObject => {
                return postCompetitionEntry({
                    referral,
                    optionId,
                    prizeOptionId,
                    answer,
                    competitionId: competitionid,
                    userId: userObject['cognito:username'],
                    tmarketing: tmarketing ? true : false,
                });
            })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    loginService.getUser(enteredCompetitionsFetch);
                    hide([$competitionTermsStatement, questions, $competitionEntryErrorMessages]);
                    showEntrySuccessMessage();
                    window.scroll(0, 0);
                } else {
                    return Promise.reject(response.reason.code);
                }
            })
            .catch(errorCode => {
                show([$competitionEntryErrorMessages]);
                showEntryErrorMessage(getErrorNode(errorCode));

                // This error has happened as a result of a signup. The account is complete
                // Take them on to the additional information form instead.
            });
    }

    function getErrorNode(errorCode) {
        switch (errorCode) {
            case 1001:
                return '#competition__entry-error-message--missing-answers';
            case 34:
                return '#competition__entry-error-message--scotland';
            case 33:
                return '#competition__entry-error-message--expired';
            case 24:
                return '#competition__entry-error-message--under-18';
        }
        return '#competition__entry-error-message--general';
    }


    function showEntryErrorMessage(selector) {
        const errorMessages = Array.from($competitionEntryErrorMessages.querySelectorAll('.competition__entry-error-message'));
        errorMessages.forEach(errorMessageNode => errorMessageNode.classList.add('hide'));
        $competitionEntryErrorMessages.querySelector(selector).classList.remove('hide');
        show([$competitionEntryErrorMessages]);
    }


    function populateAdditionalInfoFormFields(userObject) {
        let phoneNumber = userObject.phone_number;
        phoneNumber = phoneNumber && phoneNumber.replace(/[^+0-9]/g, '').replace(/^0/, '+44');
        if (phoneNumber) $additionalInfoFormNumber.value = phoneNumber;
    }

    function scrollIfNeeded($section) {
        if (
            (window.innerWidth < cssBreakPoints.beta)
            || window.location.hash.includes('competition__form')
        ) {
            scrollToElementMinusStickyNavHeight($section, 350);
        }
    }

    function loginMyStvPortal() {
        const returnUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}#competition__forms`);
        const profileUrl = `${profileOrigin}/?siteAction=remoteLogin&source=${returnUrl}`;
        return document.location.assign(profileUrl);
    }
}


export default function () {
    if (['interactive'].includes(document.readyState) && document.body) {
        run();
    } else {
        document.addEventListener('DOMContentLoaded', run, false);
    }
}