// Note: this script is reliant on
// /public/static/source/styles/6-components/_cookie-box.scss

const cookieName = 'stv-gdpr-cookie-policy-info-notice';
const Cookie = {
        // Cookie checking methods
        createCookie: (name, value, days, domain) => {
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toGMTString();
            }
            if (domain) {
                domain = ' domain=' + domain + ';';
            } else {
                domain = '';
            }
            document.cookie = name + '=' + value + expires + '; path=/;' + domain;
        },
        readCookie: name => {
          const cookieArray = document.cookie.split(';');
          const cookieFound = cookieArray.find(cookie => {
            return cookie.includes(name);
          });

          return cookieFound;
        },
        eraseCookie: name => {
            this.createCookie(name, '', -1);
        }
    };

export default function initCookieBanner() {
    // Does the cookie exist?
    if (Cookie.readCookie(cookieName)) {
        return;
    }

    // Check URL parameters for cookies=false
    // This is set by mobile apps to hide message on those devices
    const mobileApp = window.location.search.indexOf('cookies=false') > -1;

    if (mobileApp) {
        return;
    }

    if (!window.jQuery) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//core.stvfiles.com/assets/global/js/libs/jquery-1.7.2.min.js';
        document.body.appendChild(script);
        script.onload = () => {
            injectBanner();
        };
    } else {
        injectBanner();
    }

    function injectBanner() {
      const $body = $('body');
      const $box = $(`
      <div class="cookie-box cookie-out-of-view">
        <div class="cookie-box__message">
          <p>Our Services use cookies to provide, analyse, improve and personalise your experience and to show you ads based on your interests. If you continue to use our Services without changing your settings, we’ll assume you’re ok with this. For more details about cookies and how to manage them, see our <a class="cookie-link" href="//www.stv.tv/cookie-policy/">Cookie Policy</a>.</p>
        </div>
      </div>
      `);

      // remove from in app web pages
      if (!document.location.href.indexOf('android') > -1) {
          $body.append($box);
      }

      const cookieDiv = document.querySelector('.cookie-box');

      setTimeout(() => {
        cookieDiv
          .classList
          .remove('cookie-out-of-view');
      });

      let cookieRemovalTriggered = false;
      window.addEventListener('scroll', scrollHandler);

      function scrollHandler() {
        if (!cookieDiv.classList.contains('cookie-out-of-view') && !cookieRemovalTriggered) {
          cookieRemovalTriggered = true;
          window.removeEventListener('scroll', scrollHandler);
          setTimeout(() => {
            let host = location.hostname.split('.');
            host = host[host.length - 2] + '.' + host[host.length - 1];
            Cookie.createCookie(cookieName, true, 365, host);
            cookieDiv.classList.add('cookie-out-of-view');
          }, 4000);
        }
      }
    }
}
