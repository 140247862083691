
import { Component, getComponent } from '../common/stv';
import errorCodes from '../common/errorcodes';

@Component({
    name: 'validation-error'
})
export default class ValidationError {
    boundFieldComponent;

    constructor() {
        this.options = {};
    }

    componentHasMounted() {
        let root = this.elements.root;
        if (root.hasAttribute('data-bound-field')) {
            let boundFieldID = root.getAttribute('data-bound-field');
            let errorCode = root.getAttribute('data-on-error');
            let component = getComponent(boundFieldID);

            this.errorCode = errorCodes[errorCode] || undefined;

            if (component) {
                this.boundFieldComponent = component;
                this.boundFieldComponent.on('validation', this.updateState);
            }
        }

        this.reset();
    }

    reset = () => {
        // Should initially assume there's no error. Even if invalid. (aka, empty)
        this.updateState({ valid: true });
    };

    updateState = (state) => {
        state.errorCode = state.errorCode || undefined;
        let display = !state.valid && (state.errorCode === this.errorCode) ? 'block' : 'none';
        this.elements.root.style.display = display;
    };
}

window.getComponent = getComponent;
