if (typeof cookieObj === 'undefined') {
    window.cookieObj = {
        createCookie: function(name, value, days, domain) {
            // By default, we will send no expires info (a session cookie, essentially)
            var expires = '';

            if (days > 0) {
                var date = new Date();
                date.setTime(date.getTime()+(days*24*60*60*1000));
                expires = "; expires="+date.toGMTString();
            }

            if(domain){
                domain = " domain=" + domain + ";";
            } else {
                domain = "";
            }
            document.cookie = name+"="+value+expires+"; path=/;" + domain;
        },

        readCookie: function(name){
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }

            return null;
        },

        eraseCookie: function(name){
            this.createCookie(name,"",-1);
        }
    };
}

export function getCurrentDomain(domain = window.location.host) {
    let parts = domain.split('.').reverse();
    let count = parts.length;
    if (count >= 3) {
        // see if the second level domain is a common SLD.
        if (parts[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|info|biz)$/i)) {
           return parts[2] + '.' + parts[1] + '.' + parts[0];
        }
    }
    return `.${parts[1]}.${parts[0]}`;
}

export function setCookie(cookieName, data) {
    let domain = getCurrentDomain();
    cookieObj.createCookie(cookieName, data, 365, domain);
}

export function setSessionCookie(cookieName, data) {
    let domain = getCurrentDomain();
    cookieObj.createCookie(cookieName, data, 0, domain);
}

export function getCookie(cookieName) {
    return cookieObj.readCookie(cookieName);
}


export function cookieExists(cookieName) {
    if (getCookie(cookieName) === null) {
        return false;
    } else {
        return true;
    }
}

export function deleteCookie(cookieName) {
    let domain = getCurrentDomain();
    document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + domain;
};

export function deleteSiteWideCookie (cookieName) {
    let domain = getCurrentDomain();
    document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + domain + STV.route.suffix;
}