import { Component, getComponent, setStyle, delegate, ukPostcodeRegEx } from '../common/stv';
import { UAParser } from '../../../libs/ua-parser';

let handleInputBlur;
const hide = { display: 'none' };
const show = { display: 'block' };

@Component({
    name: 'address-finder',
    elements: {
        input: '[address-finder-input]',
        lockScreen: '[address-finder-locked]',
        confirmAddress: '[confirm-address]',
        addressDesc: '[address-field-desc]',
        notInUk: '[not-in-uk]'
    }
})
export default class AddressFinder {
    cpController;
    formattedAddress;
    addressSegments = [];

    constructor() {
        this.options = {
            addressFormat: '{Line1}|{Line2}|{Line3}|{Line4}|{Line5}|{City}|{Province}|{PostalCode}',
            cpDefaults: {
                key: "DF11-ZT49-RK81-KW67",
                culture: "en-uk",
                languagePreference: "eng",
                prompt: true,
                list: { height: 110 },
                bar: { showCountry: false, showLogo: false, logoLink: false, visible: false },
            }
        };
    }

    componentHasMounted() {
        const [ inputField ] = this.elements.input;
        const [ lockScreen ] = this.elements.lockScreen;
        const [ notInUkCheckbox ] = this.elements.notInUk;
        const [ confirmAddress ] = this.elements.confirmAddress;

        handleInputBlur = this.handleInputBlur.bind(this);

        let resetFormControl = delegate('[data-reset-address-finder]', (e) => {
            e.preventDefault();
            this.reset();
        });
        lockScreen.addEventListener('click', resetFormControl);

        inputField.addEventListener('blur', handleInputBlur);

        confirmAddress.addEventListener('click', event => {
            event.preventDefault();
            this.inputFieldComponent.forceValidation(true);
            this.inputFieldComponent.validate();
            this.updateDOM({ lock: true, address: inputField.value });
        });

        // toggle notInUKCheckbox action
        if (notInUkCheckbox) {
            notInUkCheckbox.addEventListener('change', this.toggleNotInUk);
        }
    }

    handleInputBlur(event) {
        // on field blur if the user passes uk post code validation then force validation
        // error which will reveal a message to select from the postcode anywhere dropdown
        const isUkPostcode = ukPostcodeRegEx.test(event.target.value.replace(/\s+/g, '').toUpperCase());

        /* If the field has successfully populated
         * then the blur occurred from a keyboard enter event.
         * No need to force validate */
        if (this.addressSegments.length) return;

        if (isUkPostcode) {
            this.inputFieldComponent.forceValidation(false);
        } else {
            this.inputFieldComponent.doNotForceValidation();
        }
        this.inputFieldComponent.validate();
    }

    toggleNotInUk = () => {
        const [ notInUkCheckbox ] = this.elements.notInUk;
        const [ inputField ] = this.elements.input;
        // toggle DOM state - greys out the address finder and disables the input
        notInUkCheckbox.parentElement.classList.toggle('stv-form__field--not-in-uk--checked');
        inputField.disabled = !inputField.disabled;
        // set 'Outside UK' if field is disabled
        if (inputField.disabled) {
            inputField.value = 'Outside UK';
            this.inputFieldComponent.forceValidation(true);
        } else if (inputField.value === 'Outside UK') {
            this.inputFieldComponent.doNotForceValidation();
            this.inputFieldComponent.validate();
            this.inputFieldComponent.reset();
        }
    };

    handlePopulate = () => {
        const [ inputField ] = this.elements.input;
        this.addressSegments = inputField.value.split('|').map(item => item.trim());
        this.formattedAddress = inputField.value;
        this.inputFieldComponent.forceValidation(true);
        this.inputFieldComponent.validate();
        this.updateDOM({ lock: true });
    };

    reset() {
        const [ notInUkCheckbox ] = this.elements.notInUk;
        const [ inputField ] = this.elements.input;
        const [ addressDesc ] = this.elements.addressDesc;
        if (notInUkCheckbox) {
            notInUkCheckbox.checked = false;
            notInUkCheckbox.parentElement.classList.remove('stv-form__field--not-in-uk--checked');
        }
        setStyle(addressDesc, show);
        inputField.disabled = false;
        this.addressSegments = [];
        this.inputFieldComponent.reset();
        this.inputFieldComponent.forceValidation(false);
        this.updateDOM({ lock: false });
    }

    updateDOM({ lock, address }) {
        const [ inputField ] = this.elements.input;
        const [ lockScreen ] = this.elements.lockScreen;
        const [ addressDesc ] = this.elements.addressDesc;
        const _address = address || this.addressSegments.filter(item => item.length ? item : undefined).join(', ');

        // remove event listener function so it doesn't validate input when
        // the value is e.g Scottish TV|120 Govan Road||||Glasgow|Lanarkshire|G51 1PQ
        // which happens when user hits enter and the input is automatically blurred.
        inputField.removeEventListener('blur', handleInputBlur);

        // Lock in the address
        if (lock) {
            lockScreen.innerHTML = `
                <span>${_address} &nbsp;</span>
                <a class="stv-form__link" href="#" data-reset-address-finder>Not your address?</a>`;

            setStyle(addressDesc, hide);
            setStyle(lockScreen, show);
            setStyle(inputField, hide);
        } else {
            setStyle(lockScreen, hide);
            setStyle(inputField, show);
            inputField.addEventListener('blur', handleInputBlur);
        }
    }
}