import loginService from '../account/initLoginService';
import { Component } from '../common/stv';
import { deleteCookie } from '../common/cookies';
import Account from './account';
import { Broadcaster }      from '../common/stv';
import { getProfileOrigin } from '../helpers';

@Component({
    name: 'account-panel'
})
export default class AccountPanel {
    account;

    componentHasMounted() {
        this.account = Account.getInstance();
        this.account.on('state-changed', this.updateState);
        this.updateState();
    }

    handleLogOutClick = () => {
        loginService.logOut();
        Broadcaster.broadcast('log-out');
        deleteCookie('STV_USERID');
        deleteCookie('STV_POSTCODE');
        deleteCookie('STV_GROUPTOKEN');
        this.updateState();
    }

    attachLogOutClickListener = () => {
        document
            .querySelector('#registration-sign-out-nav')
            .addEventListener('click', this.handleLogOutClick);
    }

    updateState = () => {
        const handleLoginSuccess = (userObject) => {
            this.render(userObject);
            this.attachLogOutClickListener();
        };
        const handleLoginFail = (err) => {
            this.render();
        };

        loginService
            .getUser()
            .then(handleLoginSuccess)
            .then(() => Broadcaster.broadcast('log-in'))
            .catch(handleLoginFail);
    }

    render = (user) => {
        let partial;
        const returnUrl = encodeURIComponent(window.location.href);
        const profileOrigin = getProfileOrigin();
        const profileUrl = `${profileOrigin}/?siteAction=remoteLogin&source=${returnUrl}`;
        if (user) {
            partial = `
                <li class="registration-avatar account-panel-controls__avatar-container">
                    <span class="account-panel-controls__avatar profile-photo ss-icon" alt="Avatar">&#x1F464;</span>
                </li>
                <li class="account-panel-controls__item">
                    <a class="account-panel-controls__link" role="link" id="registration-user-greeting" href="${profileUrl}">Hi ${user.given_name}</a>
                </li>
                <li class="account-panel-controls__item">
                    <a class="account-panel-controls__link" id="registration-sign-out-nav">Sign out</a>
                </li>`;
        } else {
            partial = `
                <li class="account-panel-controls__item">
                    <a class="account-panel-controls__link" role="link" href="${profileUrl}" data-modal-id="sign-in-modal" aria-label="Opens the sign in/Join form">Sign in/Join</a>
                </li>`;
        }

        this.elements.root.innerHTML = `
            <ul class="account-panel-controls">
                ${partial}
            </ul>`;
    };
}
